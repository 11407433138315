import React, { useEffect, useState } from "react";
import styled from "styled-components";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
//import LogoIcon from "../../assets/svg/Logo";
import {Link}  from 'react-router-dom';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import BurgerIcon from "../../assets/svg/BurgerIcon";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);


  return (
    <>

      

      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
        <NavInner className="container flex">
          <Link className="pointer flexNullCenter" to="/#home" smooth={true}>
            <Logo src="images/img_image1.png?v=1"/>
            
          </Link>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            
            <li className=" font16 pointer greenColor">
              <NavHashLink activeClassName="active" style={{ padding: "10px 15px" }} to="/#home" spy={true} smooth={true} offset={-80}>
                Beranda
              </NavHashLink>
            </li>
            <li className="font16 pointer" style={{ color: "#314814", display: "none"}}>
              <NavHashLink activeClassName="active" style={{ padding: "10px 15px" }} to="services" spy={true} smooth={true} offset={-80}>
                Belanja Produk
              </NavHashLink>
            </li>
            <li className="semiBold font15 pointer" style={{display: "none"}}>
              <HashLink  style={{ padding: "10px 15px" }} to="/#belajarHidroponik" spy={true} smooth={true} offset={-80}>
                Belajar Hidroponik
              </HashLink>
            </li>
            <li className="font16 pointer text-green">
              <NavHashLink activeClassName="active" style={{ padding: "10px 15px" }} to="/TentangKami" >
                Tentang kami
              </NavHashLink>
            </li>
            <li className="semiBold font15 pointer" style={{display: "none"}}>
              <NavHashLink activeClassName="active" style={{ padding: "10px 15px" }} to="pricing" spy={true} smooth={true} offset={-80}>
                Blog
              </NavHashLink>
            </li>
          </UlWrapper>
          
        </NavInner>
      </Wrapper>
    </>
  );
}

const Logo = styled.img`width: 60px`;
const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
  padding-left: 20px;
  @media (max-width: 760px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }

`
const BurderWrapper = styled.button`

  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    justify-content: right;
    align-items: center;
    display: block;
  }
`;

const UlWrapper = styled.ul`
  margin: 20px;
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;



