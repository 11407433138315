import React from "react";
import styled from "styled-components";

export default function ProjectBox({ img, title, text, action}) {
  return (
    <Wrapper>
      
        <img className="flexColumn" src={img} alt="project"></img>
      
      <Div className="flexColumn" style={{}}>
        <h3 className="font24 Bold greenColor" style={{paddingBottom: "0px"}}>{title}</h3>
        <p className=" font18 greenColor"
                  style={{}}
                  variant="body1">{text}</p>
      </Div>
        
    </Wrapper>
  );
}



const Div = styled.div`
padding-left : 19px;
width: 100%;
@media (max-width: 1270px){
  padding-left: 10px;

}
@media (max-width: 992px){
  p{
    font-size: 1 rem;
  }
}
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 5%;
  margin-right: 5%;
  justify-content: center;
  align-items: center;
  @media(max-width: 1400px){
    margin-right: 0px;
  }
  @media (max-width: 660px) {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  @media (max-width: 992px) {
    justify-content: center:
    width: 60%;
    
  }
  img {
    align-self : center;
    width: 96px;
    height: 96px:
    padding-bottom: 10px;
    margin: 0 0;
    


    
  }
  h3 {
    align-self : center;
    padding-right: 10px;
    padding-bottom: 10px;
  }
`;
const ImgBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.5;
  }
`;