import React from "react";
import { Link } from "react-router-dom";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";

import Pricing from "../components/Sections/Pricing.jsx";

import Footer from "../components/Sections/Footer"
import Efisiensi_Bertani from "../components/Sections/effisiensi_bertani";

import Contact1 from "../components/Sections/Contact1";
import TentangKami_Header from "../components/Tentangkami_Sections/Header";
import MisiSayuranPagi from "../components/Tentangkami_Sections/Misi";
import VisiSP from "../components/Tentangkami_Sections/VisiSP";
import SpTeam from "../components/Tentangkami_Sections/SpTeam";
import SpTeam2 from "../components/Tentangkami_Sections/SpTeam2";

export default function TentangKami() {
  return (
    <>
      <TopNavbar/>
      <TentangKami_Header/>
      
      <MisiSayuranPagi/>
      <VisiSP/>
      <SpTeam/>
     
      
      
      <Footer />
    </>
  );
}


