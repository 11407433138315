import React,{ useState } from "react";
import styled from "styled-components";

// Components
import PricingTable from "../Elements/PricingTable";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import {FaMapMarkerAlt} from "react-icons/fa"

import CoffeeShop from "../../assets/img/Mitra/CoffeShop.png?v=1"
import Restaurant from "../../assets/img/Mitra/restaurant.png?v=1"
import Hotel from "../../assets/img/Mitra/hotel.png?v=1"
import MapsButton from "../Buttons/BottonMaps";



export default function Pricing() {
  const [sidebar, setSidebar] = useState(false);

  const [subnav, setSubnav] = useState(false);
    
  const [ showSettings, setShowSettings ] = useState(true);

  const [isChanged, setIsChanged] = useState(false);
  
  const [ display, setDisplay ] = useState( 'none' )

    function Click2() {

        if ( display == 'none' ) {

            setDisplay( 'block' )

        } else if( display == 'block' ){

            setDisplay( 'none' )

        }

    }

    




  return (
    <Wrapper id="Mitra">
    <div className="greenBg" style={{paddingBottom: "130px"}}>
      <div className="container" style={{padding: "20px 0"}}>
        <HeaderInfo>
          <h1 className="font40 Bold textCenter greenColor">
            Sayuran Pagi telah melayani 
            <br/>
            puluhan tempat usaha kuliner</h1>
            <div className="row flexCenter" style={{width: "100%"}}>
              <ImgMitraKuliner src={CoffeeShop} style={{}}/>
              <ImgMitraKuliner src={Hotel} style={{}}/>
              <ImgMitraKuliner src={Restaurant} style={{}}/>
            </div>
            
          <p className="font18 textCenter PT-Sans greenColor" style={{padding : "0 2%"}}>
          Beberapa kafe, restoran, dan hotel telah mempercayakan bahan makanannya kepada Sayuran Pagi.
          </p>
        </HeaderInfo>
        <HeaderInfo>
        <h1 className="font40 Bold textCenter greenColor" style={{}}>
        Belanja Sayuran Pagi 
        <br/>
        via Online dan di Toko Fisik</h1>
        </HeaderInfo>
      
        <div className="flexCenter" style={{width: "100%"}}>
        <TablesWrapper className="flexSpaceCenter">
        
          <TableBox >
            <a className="" href="https://wa.me/6281291255366" target={"_blank"}>
              <PricingTable
                img="images/img_whatsapp1.png?v=1"
                
                title="Pesan via Whatsapp"
                text="0812 9125 5366"
                
              />
            </a>
          </TableBox>
          
          
          <TableBox>
            <a className="" href="https://www.tokopedia.com/sayuranpagi" target={"_blank"} style={{paddingTop: "10px"}}>
              <PricingTable
                  img="images/img_image11.png?v=1"
                  
                  title="Beli di Tokopedia"
                  text="Sayuran Pagi Official"
                  
                />
              </a>
          </TableBox>
          
          
          <TableBox>
            <a className="" href="https://shopee.co.id/sayuranpagi.official" target={"_blank"}>
              <PricingTable
                img="images/img_image12.png?v=1"
                
                title="Beli di Shopee"
                text="Sayuran Pagi Official"
                
              />
            </a>
          </TableBox>
        </TablesWrapper>
        </div>
        <h3 className="font25 greenColor" style={{ textAlign:"center", padding: "30px 2%"}}>
        Mitra Toko yang Menjual Produk Sayuran Pagi
        </h3>
        <ContentBox className="flexColumn whiteBg radius8 dropdown" style={{ }} >
                  <button onClick={() => setShowSettings(!showSettings)} className=" dropdown-btn"style={{height: "auto", width:"100%", padding:"20px 0"}}>
                    <Box className="flexSpaceCenter row" style={{}}>
                    
                      <img src="images/img_image13.png?v=1"alt="" style={{width: "10%"}}/>
                      
                    
                      <TextWrapper style={{ }} className="flexCenter">
                        <div className="flex row" style={{width: "100%", justifyContent: "end", alignItems: "center"}}>
                          <ContentText className="" style={{borderBottom: "2px solid #707070"}}>
                            <span className="font18 Bold PT-Sans plainColor" style={{width: "100%"}}>Halal Fresh <b className=" font18 PT-Sans plainColor regular">- 3 lokasi</b> </span>
                            
                          </ContentText>
                          {(showSettings ?
                              <RiArrowDropUpLine className="arrowIcon"/>
                            : <RiArrowDropDownLine className="arrowIcon"/> )}
                        </div>
                          
                      </TextWrapper>
                    </Box>
                  </button>
                  {(showSettings ?
                    <div class="dropdown-container2">
                      <div className="flexRight  "style={{  padding:"10px 0"}} >
                            <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0",  }}>

                              <DropdownText  className="flexColumn pointer " style={{ }}>
                                    <h4 className="plainColor font18 Bold PT-Sans">Halal fresh Cinere</h4>
                                    <p className="plainColor font14 PT-Sans">Jl. Bukit Cinere Raya No.17A, Gandul,<br/>
                                    Kec. Cinere, Kota Depok, Jawa Barat 16514</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/halalCinere" target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>

                        <div className="flexRight  "style={{  padding:"10px 0"}} >
                            <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                  <h4 className="plainColor font18 Bold PT-Sans">Halal Fresh House</h4>
                                  <p className="plainColor font14 PT-Sans">Jl. K.H.M. Yusuf Raya No.54b, Mekar Jaya,<br/>
                                  Kec. Sukmajaya, Kota Depok, Jawa Barat 16411</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/HalalFreshDepok" target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>
                        <div className="flexRight  "style={{height: "50%",  padding:"10px 0"}} >
                            <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", width:"84%", padding: "0 1%" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                  <h4 className="plainColor font18 Bold PT-Sans">Halal Fresh House Cilendek</h4>
                                  <p className="plainColor font14 PT-Sans">Jl. Brigjen Saptadji Hadiprawira No.2, RT.03/RW.13,Cilendek<br/>
                                    barat, Kec. Bogor Barat, Kota Bogor, Jawa Barat 16112</p>
 
                              </DropdownText>
                              <ButtonMap href="https://bit.ly/HalalFreshBogor" target={"_blank"} style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>
                    </div>
                    : '' )}
                  <button id="hypermart" onClick={() => setIsChanged(!isChanged)} className=" dropdown-btn"style={{height: "auto", width:"100%", padding:"20px 0"}}>
                    <Box className="flexCenter row" style={{}}>
                    
                      <img src="images/img_image21.png?v=1" alt="" style={{width: "12%"}}/>
                      
                    
                    <TextWrapper style={{  }} className="flexCenter">
                      
                    <div className="flex row" style={{width: "100%", justifyContent: "end", alignItems: "center"}}>
                          <ContentText className="" style={{borderBottom: "2px solid #707070"}}>
                            <span className="font18 Bold PT-Sans plainColor" style={{width: "100%"}}>Hypermart <b className="font18 regular PT-Sans plainColor">- 7 lokasi</b></span>
                          </ContentText>
                          
                          {(isChanged ?
                              <RiArrowDropUpLine className="arrowIcon"/>
                            : <RiArrowDropDownLine className="arrowIcon"/> )}
                             
                        </div>
                    </TextWrapper>
                    </Box>
                  </button>
                  {(isChanged ?
                  <div class="dropdown-container2" id="Hypermart" style={{}}>
                      <div className="flexRight  "style={{  padding:"10px 0"}} >
                        <ListMarket className="row flexSpaceCenter  radius6"style={{ margin: "0px 0",padding: "0 0"}}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                  <h4 className="plainColor font18 Bold PT-Sans" style={{paddingBottom: "3px"}}>Hypermart Thamrin City</h4>
                                  <p className="plainColor font14 PT-Sans">Jl. Kebon Kacang Raya RT.4/RW.9, Kecamatan Tanah Abang,<br/>
                                     Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10230</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/HypermartThamrinCity"  target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                          </ListMarket>
                      </div>

                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", padding: "0 0"}}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                  <h4 className="plainColor font18 Bold PT-Sans" style={{paddingBottom: "3px"}}>Hypermart Bella Terra</h4>
                                  <p className="plainColor font14 PT-Sans">
                                    Jl. Boulevard Raya No.1, RT.4/RW.17, 
                                    Klp. Gading Timur,<br/> Kec. Klp. Gading, 
                                    Kota Jakarta Utara, Jakarta 14240</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/HypermartBellaTerra"  target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>
                        <div className="flexRight  "style={{padding:"10px 0"}} >
                            <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", padding: "0 0" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                  <h4 className="plainColor font18 Bold PT-Sans" style={{paddingBottom: "3px"}}>Hypermart Gajah Mada</h4>
                                  <p className="plainColor font14 PT-Sans">Jl. Gajah Mada No.19, RT.2/RW.1, Petojo Utara
                                  <br/>Gambir,Kota Jakarta Pusat, Jakarta 10130</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/HypermartGajahMada"target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>
                        <div className="flexRight  "style={{padding:"10px 0"}} >
                            <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", padding: "0 0"}}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                  <h4 className="plainColor font18 Bold PT-Sans" style={{paddingBottom: "3px"}}>Hypermart Supermall Karawaci</h4>
                                  <p className="plainColor font14 PT-Sans">Supermal Karawaci, Jl. Boulevard Diponegoro, 
                                    Bencongan <br/>, Kec. Karawaci, Kabupaten Tangerang, Banten 15810</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/HypermartKarawaci"  target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>
                        <div className="flexRight "style={{ padding:"10px 0"}} >
                            <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", padding: "0 0" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                  <h4 className="plainColor font18 Bold PT-Sans" style={{paddingBottom: "3px"}}>Primo Cilandak Town Square</h4>
                                  <p className="plainColor font14 PT-Sans">Jl. TB Simatupang No.kav 17, RT.6/RW.9, Kec. 
                                    Cilandak,<br/> Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12430</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/HypermartCilandak"  target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>
                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", padding: "0 0"}}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 className="plainColor font18 Bold PT-Sans" style={{paddingBottom: "3px"}}>Foodmart - Atrium Plaza</h4>
                              <p className="plainColor font14 PT-Sans">Atrium plaza, Jl. Senen Raya No.135, RW.2, Senen, Kec. 
                              Senen,<br/> Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10410</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/FoodmartAtriumPlaza" target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>
                        <div className="flexRight  "style={{  padding:"10px 0"}} >
                            <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", padding: "0 0" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 className="plainColor font18 Bold PT-Sans" style={{paddingBottom: "3px"}}>Primo Maxxbox Karawaci </h4>
                              <p className="plainColor font14 PT-Sans">Jl. Boulevard Jend. Sudirman No.1110, Bencongan, Kecamatan<br/> Kelapa. 
                                Dua, Kabupaten Tangerang, Banten 15810</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/PrimoKarawaci" target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>
                    </div>
                              
                            : '')}
                    
                  
                    <button id="FoodStory" className=" dropdown-btn"style={{height: "auto", width:"100%", padding:"20px 0", display: "none"}}>
                    <div className="flexCenter row" style={{margin:"0px 20px"}}>
                    
                      <img src="images/img_fruitstory.png" alt="" style={{width: "14%"}}/>
                      
                    
                    <TextWrapper style={{  }} className="flexCenter">
                      
                        <div className="flexSpaceCenter" style={{borderBottom: "2px solid #707070" ,width:"100%"}}>
                          <h4 className="font16 semiBold" style={{width: "100%"}}>FoodStory - 2 Lokasi</h4>
                          <RiArrowDropDownLine size={50}/>
                          </div>
                    </TextWrapper>
                    </div>
                  </button>
                  
                    <div class="dropdown-container" id="FoodStory" style={{display:"none"}}>
                        <div className="flexRight  "style={{  padding:"10px 0"}} >
                          <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", padding: "0 0" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{}}>Fruit Story Depok</h4>
                                  <p>Jl. Margonda Raya No.201, Kemiri Muka, Kec. 
                                    Pancoran Mas, Kota Depok, Jawa Barat 16423</p>
                              </DropdownText>
                              <ButtonMap href="https://bit.ly/FoodstoryDepok"  target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                          </ListMarket>
                        </div>

                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{}}>Fruit Story Xpress</h4>
                                  <p>Jl. Puri Indah Raya No.19, RT.1/RW.6, Kembangan Sel., <br/>
                                    Kec. Kembangan, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11610</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/FoodstoryExpress"  target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                        
                    </div>
                  
                    <button id="Allfresh"  onClick={() => setSubnav(!subnav)}className=" dropdown-btn"style={{height: "auto", width:"100%", padding:"20px 0"}}>
                    <Box className="flexCenter row" style={{}}>
                    
                      <Img src="images/logoAllfresh.png?v=1" style={{}}/>
                      
                    
                    <TextWrapper style={{  }} className="flexCenter">
                      
                    <div className="flex row" style={{width: "100%", justifyContent: "end", alignItems: "center"}}>
                          <ContentText className="" style={{borderBottom: "2px solid #707070"}}>
                            <span className="font18 Bold PT-Sans plainColor" style={{width: "100%"}}>All Fresh <b className="font18 regular PT-Sans plainColor">- 7 lokasi</b></span>
                          </ContentText>
                          {(subnav ?
                              <RiArrowDropUpLine className="arrowIcon"/>
                            : <RiArrowDropDownLine className="arrowIcon"/> )}
                        </div>
                    </TextWrapper>
                    </Box>
                  </button>
                  {(subnav ?
                    <div class="dropdown-container2" id="Allfresh" >
                        <div className="flexRight  "style={{  padding:"10px 0"}} >
                          <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", padding: "0 0"}}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 className="plainColor font18 Bold PT-Sans" style={{paddingBottom: "3px"}}>All Fresh Gatot Subroto</h4>
                                  <p className="plainColor font14 PT-Sans">Jl. Gatot Subroto No.185, RT.12/RW.1, 
                                    Menteng Dalam, Tebet,<br/> Kota Jakarta 
                                    Selatan, Daerah Khusus Ibukota Jakarta 12870</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/AllfreshGatsu"  target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>

                        <div className="flexRight  "style={{ padding:"10px 0"}} >
                            <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", padding: "0 0" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                  <h4 className="plainColor font18 Bold PT-Sans" style={{paddingBottom: "3px"}}>All Fresh Pulomas </h4>
                                  <p className="plainColor font14 PT-Sans">
                                    All Fresh Avenue, Jl. Kayu Putih Raya No.1, Kayu Putih, <br/>Kecamatan
                                    Pulo Gadung, Kota Jakarta Timur, DKI Jakarta 13210</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/AllfreshPuloMas"  target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>
                        <div className="flexRight  "style={{padding:"10px 0"}} >
                            <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", padding: "0 0"}}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                  <h4 className="plainColor font18 Bold PT-Sans" style={{paddingBottom: "3px"}}>All fresh Panglima Polim</h4>
                                  <p className="plainColor font14 PT-Sans">
                                    Jl. Panglima Polim IX No.24, RT.1/RW.6, Melawai, Kec<br/>
                                     Kebayoran Baru, Kota Jakarta Selatan, DKI Jakarta 12160</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/AllfreshPolim"target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>
                        <div className="flexRight  "style={{ padding:"10px 0"}} >
                            <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", padding: "0 0" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                  <h4 className="plainColor font18 Bold PT-Sans" style={{paddingBottom: "3px"}}>All Fresh Pantai Indah Kapuk</h4>
                                  <p className="plainColor font14 PT-Sans">
                                    Jl. Pantai Indah Utara 2 Blok C1 No.185, RT.2/RW.7,Kapuk <br/>
                                    Muara ,Kec. Penjaringan, Kota Jkt Utara, DKI Jakarta 14460
                                  </p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/AllfreshPIK"  target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>
                        <div className="flexRight"style={{padding:"10px 0"}} >
                            <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", padding: "0 0"}}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                  <h4 className="plainColor font18 Bold PT-Sans" style={{paddingBottom: "3px"}}>All Fresh TB Simatupang</h4>
                                  <p className="plainColor font14 PT-Sans">
                                    Jl. TB Simatupang No.71, RT.10/RW.2, Cilandak Barat,<br/> 
                                    Kec. Cilandak, Kota Jakarta Selatan, DKI Jakarta 12430
                                    </p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/AllfreshTBsimatupang"  target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>
                        <div className="flexRight  "style={{padding:"10px 0"}} >
                            <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", padding: "0 0"}}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                <h4 className="plainColor font18 Bold PT-Sans" style={{paddingBottom: "3px"}}>All Fresh Bogor</h4>
                                <p className="plainColor font14 PT-Sans"> 
                                  Jl. Malabar 1 No.17, RT.01/RW.01, Babakan, Kecamatan<br/>
                                  Bogor Tengah, Kota Bogor, Jawa Barat 16128
                                </p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/ALlfreshBogor" target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>
                        <div className="flexRight  "style={{padding:"10px 0"}} >
                            <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", padding: "0 0" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 className="plainColor font18 Bold PT-Sans" style={{paddingBottom: "3px"}}>All Fresh Puri Kembangan</h4>
                              <p className="plainColor font14 PT-Sans"> 
                                RT.4/RW.6 Kembangan Selatan, Jl. Pesanggrahan No.168<br/> 
                                Meruya Utara, Kec. Kembangan, 11610</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/AllfreshPuri" target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>
                    </div>
                  : '' )}
                    <button id="MarketCity" onClick={() => setSidebar(!sidebar)} className=" dropdown-btn"style={{height: "auto", width:"100%", padding:"20px 0"}}>
                    <Box className="flexCenter row" style={{}}>
                    
                      <Img src="images/logoMarketCity.png?v=1" style={{}}/>
                      
                    
                    <TextWrapper style={{  }} className="flexCenter">
                      
                    <div className="flex row" style={{width: "100%", justifyContent: "end", alignItems: "center"}}>
                          <ContentText className="" style={{borderBottom: "2px solid #707070"}}>
                            <span className="font18 Bold PT-Sans plainColor" style={{width: "100%"}}>Market City <b className="font18 regular PT-Sans plainColor">- 4 lokasi</b></span>
                          </ContentText>
                          {(sidebar ?
                              <RiArrowDropUpLine className="arrowIcon"/>
                            : <RiArrowDropDownLine className="arrowIcon"/> )}
                        </div>
                    </TextWrapper>
                    </Box>
                  </button>
                  {(sidebar ?
                    <div class="dropdown-container2" id="Market City">
                        <div className="flexRight  "style={{padding:"10px 0"}} >
                          <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", padding: "0 0" }}>

                              <DropdownText  className="flexColumn pointer " style={{width: "60%"}}>
                                  <h4 className="plainColor font18 Bold PT-Sans" style={{paddingBottom: "3px"}}>Market City Kota Wisata</h4>
                                  <p className="plainColor font14 PT-Sans">
                                    Oregon Commercial Lot Blok sjc 2, Jl. Raya Kota Wisata Indonesia <br/>
                                    No.03, Ciangsana, Kec. Gn. Putri, Kabupaten Bogor, Jawa Barat</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/MarketCityKotaWisata"  target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                                <div className="textCenter" style={{}}>
                                  <MapsButton title="Lihat Peta" border />
                                </div>
                              </ButtonMap>
                          </ListMarket>
                        </div>

                        <div className="flexRight  "style={{padding:"10px 0"}} >
                            <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", padding: "0 0" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                  <h4 className="plainColor font18 Bold PT-Sans" style={{paddingBottom: "3px"}}>Market City PIK </h4>
                                  <p className="plainColor font14 PT-Sans">
                                    Jl. Pantai Indah Kapuk No.12-23, RT.6/RW.2, Kamal Muara, <br/>
                                    Penjaringan, Kota Jakarta Utara, DKI Jakarta 14470</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/MarketCityPIK"  target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>
                        <div className="flexRight  "style={{padding:"10px 0"}} >
                            <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", padding: "0 0" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                  <h4 className="plainColor font18 Bold PT-Sans" style={{paddingBottom: "3px"}}>Market City Muara Karang </h4>
                                  <p className="plainColor font14 PT-Sans">
                                    15 Jalan Muara Karang Raya 48 15 18, RT.15/RW.18, Pluit, <br/>
                                    Kec. Penjaringan, Kota Jkt Utara, DKI Jakarta 14450</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/MarketCityMuaraKarang"target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                              <div className="textCenter" style={{}}>
                                <MapsButton title="Lihat Peta" border />
                              </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>
                        <div className="flexRight  "style={{  padding:"10px 0"}} >
                            <ListMarket className="row flexSpaceCenter radius6"style={{ margin: "0px 0", padding: "0 0" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                  <h4 className="plainColor font18 Bold PT-Sans" style={{paddingBottom: "3px"}}>Market City BSD</h4>
                                  <p className="plainColor font14 PT-Sans">
                                    Jl. Bsd Raya Utama, BSD City Serpong Kavling De Park Lot 1 No.9-11,<br/>
                                    sLengkong Kulon, Pagedangan, Tangerang Regency, Banten 15331</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/MarketCityBSD"  target={"_blank"}style={{padding: "0 0px",marginRight: "7%"}}>
                                <div className="textCenter" style={{}}>
                                  <MapsButton title="Lihat Peta" border />
                                </div>
                              </ButtonMap>
                            </ListMarket>
                        </div>
                        
                    </div>
                  : '' )}

                </ContentBox>
        
      </div>
    </div>
  </Wrapper>
    
  );
}


const Box = styled.div`
margin: 0 20px;
@media (max-width: 500px){
  margin: 0 10px;
}
`;

const ListMarket = styled.div`
width:84%;
padding: 0 1%;

@media(max-width: 844px){
  flex-direction: column;
  align-items: start;
}
`;

const ContentText = styled.div`
width:90%;
@media (max-width: 506px){
  width: 86%;
}
`;


const ImgMitraKuliner = styled.img`
width: 264px;
marginRight: 2%;
@media (max-width: 824px){
  width: 204px;
  
}

@media (max-width: 612px){
  width: 124px;
  
}
@media (max-width: 387px){
  width: 104px;
  
}
`;

const Img = styled.img`

width: 12%;
@media (max-width: 1024px){
  
  
}

`;

const DropdownText= styled.div`

width: 60%;
@media (max-width: 843px){
  padding-bottom: 10px;
  }
@media (max-width: 530px){
  width: 72%;
  }
`;

const ButtonMap = styled.a`
padding: 0 20px;

margin: 0 10px;
display: flex;
@media (max-width: 947px){
padding: 10px 0px;

margin: 0 0px;

}
`;

const Wrapper = styled.section`
  position: relative;
  top: -2px;
  width: 100%;
  padding: 0px 0;
  
`;
const HeaderInfo = styled.div`
  padding-top: 80px;
  margin-bottom: 0px;
  @media (max-width: 1060px) {
    text-align: center;
  }
  @media (max-width: 859px) {
    padding-top: 20px;
    text-align: center;
    h1{
      line-height: 2.3rem;
    }
    
  }
  @media (max-width: 430px) {
    text-align: center;
    padding: 20px 2%;
    h1{
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
`;
const TablesWrapper = styled.div`
  
  padding-top: 54px;
  margin: 0 3%;
  flex-direction: row;
  @media (max-width: 1060px) {
    flex-direction: column;
    padding-top: 24px;
  }
  @media (max-width: 436px) {
    flex-direction: column;
    padding-top: 4px;
  }
`;

const ContentBox =styled.div`
height: 550px;
width: 80%;
padding: 0px 0;
margin:0px 10%;
overflow-y:scroll;



`;
const TableBox = styled.div`
  width: 340px;
  
  @media (max-width: 1060px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
    padding-top: 30px;
  }
  @media (max-width: 1170px) {
    width: 331px;
    max-width: 370px;
    margin: 0 auto
  }
`;

const TextWrapper =styled.div`
margin: 0px 0;
width: 88%;





`;




