import React from "react";
import styled from "styled-components";
// Components
import Keunggulan from "../Elements/keunggulan";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/projects/i1.png";
import ProjectImg2 from "../../assets/img/projects/i2.png";
import ProjectImg3 from "../../assets/img/projects/i3.png";
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/2.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";

export default function Services() {
  return (
    <Wrapper id="services" className="" style={{  }}>
      
      <div className="bg" >
        <ContentWrapper className="flexCenter row container">
          
          <TextWrapper className="flexColumn  ">
            <TextContent className="flex flexRight " style={{}}>
              <TextRight className="flexColumn radius8" >
                <h2
                  className="font40 greenColor Bold"
                  as="h3"
                  variant="h3"
                >
                  Sehat dan Bernutrisi
                </h2>
                <p
                  className=" font18 plainColor PT-Sans reguler"
                  style={{paddingTop: "10px"}}
                  variant="body1"
                >
                  Sayuran hidroponik ditumbuhkan dalam kondisi ideal, 
                  PH yang seimbang, dan dilengkapi dengan nutrisi yang 
                  sesuai dengan kebutuhan tanaman.
                </p>
              </TextRight>
            </TextContent>
            <TextLeft className="flexColumn radius8" style={{ }}>
              <h2
                className="font40 greenColor Bold"
                as="h3"
                variant="h3"
              >
                Kesegaran yang Terpelihara
              </h2>
              <p
                className=" font18 plainColor PT-Sans"
                style={{paddingTop: "10px"}}
                variant="body1"
                
              >
                Sayuran dikemas dan dikirim tidak lama setelah panen di
                kebun. Dengan kemasan yang aman, sayuranmu dijamin segar
                sampai tujuan.
              </p>
            </TextLeft>
            <TextContent className="flex flexRight " style={{}}>
              <TextRight className="flexColumn radius8" style={{}}>
                <h2
                  className="font40 greenColor Bold"
                  as="h3"
                  variant="h3"
                >
                  Harga yang Stabil
                </h2>
                <p
                  className=" font18 plainColor PT-Sans"
                  style={{paddingTop: "10px"}}
                  variant="body1"
                >
                  Masa panen sayuran hidroponik bisa diprediksi dan dijadwalkan. 
                  Tidak ada kelangkaan dan harga tidak akan naik dengan tiba-tiba. 

                </p>
              </TextRight>
            </TextContent>
            <TextLeft className="flexColumn radius8" style={{}}>
              <h2
               className="font40 greenColor Bold"as="h3"
                variant="h3"
              >
                Proses yang Berkelanjutan
              </h2>
              <p
                className=" font18 plainColor PT-Sans"
                style={{paddingTop: "10px"}}
                variant="body1"
              >
                Teknologi hidroponik menghemat lebih dari 90% air 
                dibanding sistem konvensional, tidak merusak tanah 
                dengan penggunaan pestisida, sehingga berdampak baik pada lingkungan.
              </p>
            </TextLeft>
          </TextWrapper>
          
        </ContentWrapper>
        
      </div>
    </Wrapper>
  );
}

const AdvertisingWrapper = styled.div `width: 100%;
max-width: 1270px;
margin: 0 auto;
padding: 0px 30px;
justify-content: center;
@media (max-width: 859px) {

  padding: 0 15px;
}`;

const Video = styled.video`
  padding-top: 140px ;
  width: 100%;
  @media (max-width: 1240px) {
    width: 100%;
    padding-top: 180px ;
    
  }
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;

const TextContent = styled.div`

`;

const TextRight = styled.div`
padding-bottom: 5%;
justify-items: end;
width: 550px;
text-align: right;
@media (max-width: 1428){
  padding-right: 100px;
}
@media (max-width: 1036px)
{
  width: 500px;
}

@media (max-width: 790px)
{
  width: 100%;
  padding: 3%;
  background-color: rgba(255, 255, 255, 0.6);
}
`;
const dummyDiv = styled.div`
background-color: rgba(255, 255, 255, 0.459);
`;

const TextLeft = styled.div`
padding-bottom: 5%;
align-content: flex-start;
width: 670px;
text-align: left;
padding-left: 120px;

  @media (max-width: 1036px)
  {
    width: 600px;
  }

  @media (min-width: 1400px)
  {
    padding-left: 60px;
  }

  @media (max-width: 860px)
  {
    padding-left: 90px;
  }

  @media (max-width: 790px)
  {
    padding-bottom: 0px;
    margin: 2% 0;
    padding: 2%;
    width: 99%;
    background-color: rgba(255, 255, 255, 0.6);
  }
  @media (max-width: 860px)
  {
    h2{
      line-height: 2.2rem;
    }
    
  }
`;

const Container = styled.div`
  width: 100%;
  height: 50%;
  padding 50px;
  justify-content: center;
  align-items: center;
  

`;

const TextWrapper = styled.div`
  padding-left: 10%;
  Padding-right: 15%;
  width: 100% ;
  z-index:5;
  display:flex;
  position: relative;

  @media (min-width: 1400px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (max-width: 1050px) {
    width: 100%;
    

    
    
    
  }
  @media (max-width: 792px) {
    width: 100%;
    padding-left: 10%;
    Padding-right: 10%;

    
    
    
  }
  
}
`;


const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (min-width: 1400px){
    width: 1120px;
  }
  
  
  
}
`;


const Wrapper = styled.section`
  width: 100%;
  padding-top: 100px;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 1060px) {
    padding-top: 40px;
  }
  @media (max-width: 850px) {
    height: auto;
    position: relative;
    bottom: -80px;
  }
  
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  
  margin: 0px 0;
  padding: 100px 0;
  position: relative;
  
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 40%;
  p {
    max-width: 475px;
  }
  @media (max-width: 1240px) {
    width: 50%;
    p {
      max-width: 505px;
    }
  }

  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 60%;
  position: absolute;
  top: -70px;
  right: 0;
  padding-left: 5%;
  
  @media (max-width: 1240px) {
    width: 50%;
    
  }

  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

