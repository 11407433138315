import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/projects/i1.png?v=1";
import ProjectImg2 from "../../assets/img/projects/i2.png?v=1";
import ProjectImg3 from "../../assets/img/projects/i3.png?v=1";


export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container flexCenter">
         
          <Content className="col-xs-12 col-sm-12 col-md-12 col-lg-12 "style={{}}>
            <div className="gradient row flexCenter radius8 " style={{PaddingTop : "10px"}}>
            <div className="col-xs-10 col-sm-11 col-md-10 col-lg-4 flexCenter " style={{ width: "100%", PaddingTop : "10px"}}>
              <ProjectBox
                img={ProjectImg1}
                title="Lebih Sehat"
                text="Sayuran ditumbuhkan dengan nutrisi yang sesuai dan dikemas secara higienis."
                
              />
              <div><Line className="darkBg" /></div>

            </div>
            
            <div className="col-xs-10 col-sm-11 col-md-10 col-lg-4 flexCenter" style={{ width: "100%"}}>
              <ProjectBox
                img={ProjectImg3}
                title="Pasti Segar"
                text="Sayuran dikirim segera setelah dipanen, kesegarannya terpelihara."
                
              />
              <div><Line className="darkBg" /></div>
            </div>
            
            <div className="col-xs-10 col-sm-11 col-md-10 col-lg-4" style={{ width: "100%"}}>
            <ProjectBox
                img={ProjectImg2}
                title="Harga Stabil"
                text="Masa panen yang terjadwal mencegah kelangkaan dan harga meroket."
                
              />
            </div>
            </div>
          </Content>
          
          
        </div>
      </div>
      
    </Wrapper>
  );
}

const Content = styled.div`
max-width: 85% ;

@media (max-width: 1400px){
  max-width: 100%;
  padding-left: 15%;
  Padding-right: 15%;
}

@media (max-width: 1140px) {
  max-width: 92%;
  
}

@media (max-width: 992px) {
  padding: 10%;
  max-width: 85%;
  
}
@media (max-width: 572px) {
  padding: 0px;
  max-width: 85%;
  
}
`;

const Wrapper = styled.section`
  width: 100%;
  padding-bottom: 50px;
  @media(max-width: 760px){
    padding-bottom: 0px;
  }
`;

const Line = styled.div`
 width: 1px;
 height: 79px;
 margin-top: 10px;
 
 

 @media (max-width: 1200px) {
  width: 0px;
  height: 0px;
  
  padding 0px;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
