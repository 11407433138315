import React from "react";

// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";

import Pricing from "../components/Sections/Pricing.jsx";

import Footer from "../components/Sections/Footer"
import Efisiensi_Bertani from "../components/Sections/effisiensi_bertani";

import Contact from "../components/Sections/Contact1";

export default function Landing() {
  return (
    <>
      
      <Header />
      <Projects />
      <Services />
      <Efisiensi_Bertani />
      
      <Pricing />
      <Footer />
    </>
  );
}


