import React from "react";
import styled from "styled-components";
// Components

import {RiDoubleQuotesL} from "react-icons/ri"
import {RiDoubleQuotesR} from "react-icons/ri"

export default function SpTeam() {
  return (
    <Wrapper id="projects " >
      <div className="flexCenter" style={{}}>
        <VisiContent className="container flexCenter BgWhite" style={{ }}>
          
          <Content style={{textAlign: "center"}}>
            <h1  className=" font40 Bold plainColor " style={{paddingBottom: "20px"}}>Tim Sayuran Pagi</h1>
            <p className="font18 PT-Sans plainColor">
            Belajar dan bertumbuh menjadi nilai utama yang kami 
            pegang untuk menyediakan produk dan pelayanan yang 
            berkualitas. Berasal dari banyak latar belakang 
            pendidikan, membuat kami menjadi tim yang solid untuk 
            menciptakan ekosistem baru pada bidang pertanian yang 
            lebih efisien, modern, dan menguntungkan. Kami berharap 
            hal ini bisa menjadi tonggak peradaban baru dalam dunia 
            pertanian di Indonesia.

            </p>
          </Content>
          <Img src="images/tree.png?v=1" alt="" style={{}}/>
          
          
        </VisiContent>
      </div>
      
    </Wrapper>
  );
}

const Img = styled.img`
width: 60% ;
@media(max-width: 650px){
  width: 100%;
}

`;

const Content = styled.div`
max-width: 1100px;
flex-direction: column;

padding-bottom: 80px;
p{
  max-width: 100%;
}
}
@media (max-width: 1440px) {
  
  
}

@media (max-width: 992px) {
  
  
}
`;

const Wrapper = styled.section`
  position: relative;
  bottom: -2px;
  padding-bottom: 0px;
  width: 100%;
  
`;

const VisiContent = styled.div`

width: 90%;
flex-direction: column; 

@media (max-width: 1430px){
  
}
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
