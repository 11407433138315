import React from "react";
import { Helmet } from "react-helmet";
import ProjectRoutes from "./Routes.js";
// Screens
import Landing from "./screens/Landing.jsx";

export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Palanquin:wght@400;500;600;700;800&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400&family=Palanquin:wght@700&display=swap" rel="stylesheet"></link>
      </Helmet>
      <ProjectRoutes />
    </>
  );
}

