import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets
import ClientLogo01 from "../../assets/img/clients/logo01.svg"
import ClientLogo02 from "../../assets/img/clients/logo02.svg";
import ClientLogo03 from "../../assets/img/clients/logo03.svg";
import ClientLogo04 from "../../assets/img/clients/logo04.svg";
import ClientLogo05 from "../../assets/img/clients/logo05.svg";
import ClientLogo06 from "../../assets/img/clients/logo06.svg";

export default function Keunggulan() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    
      <Wrapper className="radius8">
        
          <Content className="">
            <Content2>
                <Img
                src="images/icon Lebih Sehat 1.png"
                
                />
                <TextWrapper className="flexColumn">
                
                  <h3
                    className="extraBold font25"
                    as="h6"
                    variant="h6"
                  >
                    Lebih Sehat
                  </h3>
                
                
                  <Paragraf
                    className="font18"
                    variant="body1"
                  >
                    Kaya nutrisi dan bebas pestisida.
                  </Paragraf>
                
                </TextWrapper>   
              </Content2>       
              <Line className="darkBg" />
              <Content2>
              <Img 
                src="images/icon_Pasti_Segar.png"
                
              />
              <TextWrapper className="flexColumn">
                <h3
                  className="extraBold font25"
                  as="h6"
                  variant="h6"
                >
                  Pasti Segar
                </h3>
                <Paragraf
                  className="font18"
                  variant="body1"
                >
                  Sayuran dikirim segera setelah dipanen.
                </Paragraf>
              </TextWrapper>
              </Content2>                
              <Line className="darkBg" />
              <Content2>        
                <Img 
                  src="images/icon_Harga_Stabil.png"
                  style={{padding: "0px 14px 0px 12px"}}
                />
                <TextWrapper className="flexColumn">
                  <h3
                    className="extraBold font25"
                    as="h6"
                    variant="h6"
                  >
                   Harga Stabil
                  </h3>
                  <Paragraf
                     className="font18"
                     variant="body1"
                  >
                     Tidak ada kelangkaan dan harga yang meroket.
                  </Paragraf>
                </TextWrapper>
                </Content2>        
            </Content>
                      
        </Wrapper>
    
  );
}

const Paragraf = styled.p`
  width: 200px;
  @media (max-width: 960px) {
    width: 150px;
  }
`;

const TextWrapper = styled.div`
  display : flex;
  justify-content: flex-start;
  width: 75%;
  padding-right: 14px;
  @media (max-width: 800px) {
    width: 70%;
  }


`;

const Line = styled.div`
 width: 1px;
 height: 77px;
 z-index:9;
 padding 1px;
 @media (max-width: 800px) {
  width: 0px;
 height: 0px;
}
`;

const Img = styled.img`
 
 
 Padding-right: 20px;
 padding-left: 10px;
 @media (max-width: 960px) {
  width: 12%;
    
  }
  @media (max-width: 800px) {
    width: 40%;
  }
`;

const Content2 = styled.div`
flex-direction: row;

@media (max-width: 800px) {
  flex-direction: row;
  display: flex
}
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  place-items: start;
  justify-content: space-between;
  
  padding-bottom: 41px;
  padding-right: 24px;
  
  justify-content: center
  @media (max-width: 960px) {
    padding-top: 20px;
    padding-bottom: 31px;
    padding-right: 14px;
    
    
    max-width: 70%;
  }
  @media (max-width: 800px) {
    flex-direction: column;
  }
  
`;
const Wrapper = styled.div`
 display :flex;
 place-items: center;
 justify-content: center;
 
 padding: 20px;
 background: rgb(49,72,20);background: linear-gradient(180deg, rgba(213,254,162,1) 10%, rgba(255,255,255,1) 90%);

 @media (max-width: 960px) {
  justify-content: center;
  max-width: 100%;
  padding: 20px;
}
@media (max-width: 792px) {
  justify-content: center;
  width: 90%;
  height: 400px;
  padding: 20px;
}
`;


const LogoWrapper = styled.div`
  
  height: 100px;
  cursor: pointer;
  :focus-visible {
    outdiv: none;
    border: 0px;
  }
`;
const imgStyle = styled.img`
  width: 100%;
  height: 100%;
  padding: 10%;
`;