import React,{ useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import styled from "styled-components";
// Components

// Assets

import SecondaryButton from '../Buttons/SecondaryButton';



export default function Belajar_Hidroponik() {
  const form = useRef();

  const [result, showResult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_l7twgdq", "template_e3z8nyr", e.target, "Xgek9Edta9O06aW2r")
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      showResult(true);
  };
  return (

    <Wrapper id="belajarHidroponik" className="" style={{  }}>
      <div className="" >
      <div className="">
          <AdvertisingWrapper className="gradient2">
            <Advertising className="flex">
              <AddLeft className="bgFoto">
                <img src="images/img_foto.png" />
                  
              </AddLeft>
              <AddRight>
                <AddRightInner>
                <h2 className="font60 Bold orangeColor">Belajar Hidroponik</h2>
                <h4 className="bold font25 greenColor">Benarkah Hidroponik Boros Air?</h4>
                <p className="font18 regular Paragraf plainColor" >
                Bagi orang yang belum pernah bersinggungan dengan dunia 
                hidroponik mungkin akan memiliki pertanyaan demikian, 
                “tidakkah hidroponik boros air?”. Apalagi bila mereka 
                tergolong orang-orang yang peduli lingkungan. Hal ini 
                akan jadi sebuah pertanyaan menggelitik sebelum mencoba 
                memulai lebih jauh. Jadi apakah benar sistem hidroponik 
                boros air?
                </p>
                
              <h4 className="bold font25 greenColor">Selengkapnya Informasi Hidroponik di Email-mu</h4>
              <p className='font18 regular Paragraf plainColor'>Dapatkan beragam pengetahuan hidroponik dan informasi seminar/pelatihan terbaru melalui email.</p>
              <Form onSubmit={sendEmail} ref={form} >

                <input placeholder=' youremail@domain.com' type="text" id="email" name="email" className="font18 PT-Sans reguler greyPlaceholdeColor" />
                <ButtonsRow className="flexNullCenter" style={{ margin: "10px 0" }}>
                
                  <div className="textCenter" style={{}}>
                    <SecondaryButton title="Daftar" type="submit" value="Send" action={() => alert("Pendaftaran mu sudah diterkirim ")} border />
                  </div>
                </ButtonsRow>
                
              </Form>

              

                </AddRightInner>
              </AddRight>
            </Advertising>
          </AdvertisingWrapper>
          
        </div>
      </div>
      
          
          
          
          
        
    </Wrapper>
    
  );
}

const Form = styled.form`
  padding: 10px 0 30px 0;
  input,
  textarea {
    width: 65%;
    background-color: #ffffff;
    border-radius: 0.7rem;
    outline: none;
    box-shadow: none;
    border: 1px solid #707070;
    height: 50px;
    margin-bottom: 5px;
  }
  textarea {
    min-height: 200px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
  @media(max-width: 560px){
    input {
      width: 88%;
    }
  }
`;

const AdvertisingWrapper = styled.div `width: 100%;
z-index: 9;
margin: 0 auto;
padding: 0px 0px;
justify-content: center;
@media (max-width: 859px) {

  padding: 0 0px;
}`;

const Video = styled.video`
  padding-top: 140px ;
  width: 100%;
  @media (max-width: 1240px) {
    width: 100%;
    padding-top: 180px ;
    
  }
  @media (max-width: 560px) {
    width: 120%;
    height: auto;
  }
`;

const TextContent = styled.div`

`;

const TextRight = styled.div`
padding-bottom: 5%;
justify-items: end;
width: 550px;
text-align: right;
@media (max-width: 1428){
  padding-right: 100px;
}
@media (max-width: 1036px)
{
  width: 500px;
}

@media (max-width: 790px)
{
  width: 100%;
}
`;
const dummyDiv = styled.div`
background-color: rgba(255, 255, 255, 0.459);
`;

const TextLeft = styled.div`
padding-bottom: 5%;
align-content: flex-start;
width: 670px;
text-align: left;
padding-left: 120px;

  @media (max-width: 1036px)
  {
    width: 600px;
  }

  @media (max-width: 790px)
  {
    padding-left: 0px;
    width: 90%;
  }

`;

const Container = styled.div`
  width: 100%;
  height: 50%;
  padding 50px;
  justify-content: center;
  align-items: center;
  

`;

const TextWrapper = styled.div`
  padding-left: 10%;
  Padding-right: 15%;
  width: 100% ;
  z-index:5;
  display:flex;
  position: relative;

  @media (min-width: 1428px) {
    padding-left: 17%;
    padding-right: 17%;
  }
  @media (max-width: 1050px) {
    width: 100%;
    

    
    
    
  }
  @media (max-width: 700px) {
    width: 100%;
    padding-left: 10%;
    Padding-right: 10%;

    background-color: rgba(255, 255, 255, 0.459);;
    
    
  }
  
}
`;
const ImageWrapper = styled.div`
  
  z-index: 9;
  display:flex;
  position: relative;
  justify-content: center;
  
  
}
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  
  
}
`;
const TableBox = styled.div`
  
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;

const Wrapper = styled.section`
  position :relative;
  width: 100%;
  top: -2px;
  justify-content: center;
  align-items: center;
  

  
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  
  
  position: relative;
  
  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 0 0 0px 0;
    margin: 0px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  z-index: -1;
  width: 50%;
  
  

  img{
    
    display: none;
    left:0 ;
    right: 0;
    overflow:hidden;
    right: 50px
  }


 
 

  @media (max-width: 1000px) {
    width: 100%;
    img{
      width: 100%;
      display: flex;
    }
  }
`;
const AddRight = styled.div`
  
  padding-top: 3%;
  padding-left: 40px;
  width: 50%;
  
  
  right: 0;
  
  
  
  
  @media (max-width: 1000px) {
    width: 100%;
    order: 2;
    padding-left: 20px;
    
  }
`;
const AddRightInner = styled.div`
  align-items: center;
  width: 100%;
  h2{
    
    max-width: 50%;
    line-height: 3.8rem;
  }
  h4{
    padding-top: 2%;
  }
  p{
    padding-top: 10px;
    padding-right: 10%;
    max-width: 80%;
  }
  
  @media (max-width: 860px) {
    padding-left: 10%;
    width: 100%;
    position: relative;
    order: 1;
    h2{
      line-height: 3.2rem;
    }
    
  }
  @media (max-width: 560px){
    padding-left: 0px;
    h4{
      font-size: 1.55rem;
      padding-top: 10px;
      padding: 0 2%;
    }
    p{
      padding: 0 2%;
      max-width:100%;
      font-size: 1.138rem;
    }
  }

`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
