import React from "react";
import styled from "styled-components";
// Components

import {RiDoubleQuotesL} from "react-icons/ri"
import {RiDoubleQuotesR} from "react-icons/ri"

export default function SpTeam2() {
  return (
    <Wrapper id="projects " className=" flex bgFotoTeamSP" style={{justifyContent: "end", alignContent: "end"}} >
      <div className="flex container " style={{}}>
      <ContentWrapper className=" flex ">
        <LeftSide className="flex" style={{}}>
          <Content>
          
            
            <h1  className=" font40 Bold plainColor " style={{paddingBottom: "20px"}}>Tim Sayuran Pagi</h1>
            <HeaderP className="font18 textLeft plainColor PT-Sans">
            Belajar dan bertumbuh menjadi nilai utama yang kami 
            pegang untuk menyediakan produk dan pelayanan yang 
            berkualitas. Berasal dari banyak latar belakang 
            pendidikan, membuat kami menjadi tim yang solid untuk 
            menciptakan ekosistem baru pada bidang pertanian yang 
            lebih efisien, modern, dan menguntungkan. Kami berharap 
            hal ini bisa menjadi tonggak peradaban baru dalam dunia 
            pertanian di Indonesia.
            </HeaderP>
            
          </Content>
        </LeftSide>
        <RightSide className="">
          
          
        </RightSide>
      </ContentWrapper>
      </div>
      
    </Wrapper>
  );
}


const HeaderP = styled.div`
  
  
  padding: 15px 0px 50px 0px;
  line-height: 1.9rem;
  @media (max-width: 1220px) {
    
    width: 100%;
  }
  @media (max-width: 960px) {
    
    
    padding: 2px 0px 0px 0px;
    width: 100%;
  }
`;

const RightSide = styled.div`

  margin-left: 3%;
  width: 50%;
  height: 100%;
  @media (max-width: 1290px) {
    align-items: start;
  }
  @media (max-width: 1060px) {
    width: 100%;
    order: 2;
    margin-top: 30px;
    margin-left: 0px;
  }
`;

const LeftSide = styled.div`
  justify-content: center;
  align-items: end;
 
  width: 50%;
  height: 100%;
  @media (max-width: 1360px){
    margin-left: 2%;
  }
  
  padding-bottom: 40px;
  @media (max-width: 960px) {
    width: 96%;
    order: 1;
    margin: 20px 0 0 0;
    text-align: center;
    align-items: start;
  }
  @media (max-width: 560px) {
    margin: 0px 0 0px 0;
  }
  @media (max-width: 1170px) {
    width: 93%;
    padding-bottom: 10px;
    margin-left: 2%;
  }
`;



const ContentWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0px 0px;
  @media (max-width: 1060px) {
    padding-top : 0px;
    
    flex-direction: column;
  }
`;

const Content = styled.div`

flex-direction: column;
display: flex;
justify-content: center;
padding-bottom: 40px;
p{
  max-width: 100%;
}
}
@media (max-width: 1060px) {
  h1{
    text-align: left;
  }
  
}

@media (max-width: 992px) {
  
  
}
`;

const Wrapper = styled.section`
  position: relative;
  height: 1200px;
  bottom: -70px;
  padding-bottom: 0px;
  width: 100%;
  @media(max-width: 1300px){
    height: 1100px;
  }
  @media(max-width: 940px){
    height: 970px;
    bottom: -40px;
  }
  @media(max-width: 740px){
    height: 900px;
    bottom: -40px;
  }
  @media(max-width: 600px){
    height: 800px;
    bottom: -40px;
  }
  @media(max-width: 470px){
    height: 700px;
    bottom: -40px;
  }
  
`;

const VisiContent = styled.div`
max-width: 1200px;
flex-direction: column; 

@media (max-width: 1430px){
  max-width: 98%;
}
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
