import React from "react";
import styled from "styled-components";
import { HashLink, NavHashLink } from 'react-router-hash-link';
// Components
import FullButton from "../Buttons/FullButton";

export default function Header() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="">
        <Content>
          <h1 className="Bold font60 greenColor"> Hidup Bugar dengan Sayuran Segar</h1>
          <HeaderP className="font18 plainColor">
                  Hidup sehat dengan sayuran kaya manfaat yang ditumbuhkan secara berkelanjutan.
                  Penuhi nutrisi harian keluarga dan konsumenmu dengan sayuran segar dari kebun hidroponik Sayuran Pagi by Lokatani.
          </HeaderP>
          <BtnWrapper className="">
            <RowButton style={{  }}>
              <HashLink className="whiteColor" to="/#Mitra" spy={true} smooth={true} offset={-80}><FullButton title="Belanja untuk Rumah" /></HashLink>
            </RowButton>
    
            <RowButton style={{ }}>
              <a className="whiteColor" href="https://wa.me/6282299655727" target={"_blank"}><FullButton title="Berlangganan untuk Bisnis" /></a>
            </RowButton>
          </BtnWrapper>
        </Content>
      </LeftSide>
      <RightSide>
        <ImageWrapper className="">
          <Img className="" src="images/Headevideo.gif?v=1" 
                       style={{zIndex: 9}} />
        </ImageWrapper>
        
      </RightSide>
    </Wrapper>
  );
}

const RowButton = styled.div`
width: 280px; 
height: 52px;
margin: 0 0px;

@media (max-width: 1240px) {
    height: auto;
    width: 260px;
    margin : 0 10px 0px 0px
  }
@media (max-width: 1025px) {
    height: auto;
    width: 260px;
    margin : 0 5px 0px 0px
  }

@media (max-width: 570px){
  margin : 10px 0;
  width: 320px;
}
`;

const Content = styled.div`
width: 100%;
@media(max-width: 700px){
  h1{
    line-height: 3.5rem;
  }
}
@media(max-width: 440px){
  h1{
    line-height: 3.2rem;
    font-size: 2.7rem;
  }
}
`;

const Wrapper = styled.section`
  padding-top: 9%;
  padding-bottom: 11%;
  width: 100%;
  
  @media (max-width: 960px) {
    padding-top: 80px;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    padding-bottom: 0px;
  }
  
  
`;
const LeftSide = styled.div`
  margin-left: 2%;
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 0px 0 30px 0;
  }
`;
const RightSide = styled.div`

  margin-left: 4%;
  width: 100%;
  height: 100%;
  @media (max-width: 1290px) {
    align-items: start;
  }
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin-top: 30px;
    margin-left: 0px;
    padding-left: 0px;
  }
  @media (max-width: 572px){
    padding-bottom: 50px;
  }
`;
const HeaderP = styled.div`
  width: 600px;
  padding: 15px 0px 50px 0px;
  line-height: 1.9rem;
  @media (max-width: 1220px) {
    
    width: 100%;
  }
  @media (max-width: 960px) {
    
    text-align: center;
    padding: 20px 0px 30px 0px;
    width: 100%;
  }
  @media(max-width: 440px){
    line-height: 1.6rem;
  }
`;
const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 600px;
  margin: 20px 0px;

  @media (max-width: 1310px) {
    height: auto;
    width: 100%;
    
  }

  @media (max-width: 960px) {
    margin: 0 auto;
    width: 100%;
  }
  @media (max-width: 570px) {
    justify-content: space-between;
    margin: 0 auto;
    flex-direction:column;
    width: 100%;

  }`;
const GreyDiv = styled.div`
  width: 30%;
  height: 60%;
  position: absolute;
  top: 200px;
  right: 80px;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  
  position: relative;
  
  padding: 0 0px;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  border-top-left-radius: 5.5rem;
  border-bottom-right-radius: 5.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  width: 100%;
  filter: drop-shadow(8px 10px 10px rgb(0 0 0 / 0.5));
  

  @media (max-width: 1260px) {
    height: 50%;
    width: 100%;
  }

  @media (max-width: 960px) {
    height: auto;
    width: 100%;
  }
`;
