import React from "react";
import styled from "styled-components";

export default function ProjectBox1({ img, title, text, action}) {
  return (
    <Wrapper className="row">
       <Img>
        <ImgBtn className="aniamte pointer">
          <img className="radius8" src={img} alt="project"></img>
        </ImgBtn>
      </Img>
      <TextContent className="" style={{}}>
        <h3 className="font18 extraBoldWhite" style={{width: "96%"}}>{title}</h3>
        <p className="font18 whiteColor Paragraf" >{text}</p>
      </TextContent>
    </Wrapper>
  );
}

const Img =styled.div`

@media(max-width: 767px){
  width: 20%;
}

`;

const TextContent = styled.div`

@media(max-width: 767px){
  width: 80%;
}
@media(max-width: 436px){
  width: 80%;
}
`;



const Wrapper = styled.div`
  align-items: center;
  @media (max-width: 1240px){
    
    padding-right: 7%;

  }
  
  
  img {
    width: 100%;
    height: auto;
    margin: 10px 0;
    padding-right: 40px;
  }
  h3 {
    padding-bottom: 10px;
  }

  @media (max-width: 767px) {
    flex-direction: row;
    justify-content: Center;
    display: flex;
    align-items: center;
    text-align: left;
    img{
      padding-right: 0px;
    }
    h3{
      font-size: 1.185rem;
      padding-bottom: 0px;
    }

    p{
      max-width: 100%;
      line-height: 1.5rem;
      justify-content: Center;
      align-items: center;
      display: flex;
      
    }
    
  }
  @media(max-width: 560px){
    justify-content: start;
  }
`;
const ImgBtn = styled.div`

  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  

  @media (max-width: 767px) {
    justify-content: Center;
    display: flex
    align-items: center;
    width:100%;
    
  }
`;