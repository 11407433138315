import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

export default function Footer() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkGreenBg">
        <div className="container">
          <InnerWrapper className="flexCenter" style={{ padding: "50px 20px" }}>
            
            <StyleP className="whiteColor font13">
              <div className="Row flexCenter">
                <Content style={{}}>
                    <h4 className="font18 Bold whiteColor" style={{textAlign : "right"}}>
                    Sayuran Pagi
                    </h4>
                    <p style={{textAlign : "right"}}>
                      <a className="whiteColor font16" href="https://goo.gl/maps/MaQGZcA7d5xbrVSD8" target={"_blank"}>
                      Jalan Terong No.1<br/>
                      Kec.Beji, Depok<br/>
                      Jawa Barat 16421
                        
                      </a>
                    </p>
                </Content>
                <div style={{marginLeft : "8px",marginRight:"0px"}}>
                  <Logo src="images/img_image1.png?v=1"/>
                </div>
                <div style={{marginLeft : "8px",marginRight:"8px"}}>
                  <Logo src="images/Logo_Lokatani.png?v=1"/>
                </div>
                <Content style={{}}>
                      <h4 className="font18 Bold" style={{textAlign : "left"}}>
                        Hubungi Kami
                      </h4>
                      <p>
                        <a className="whiteColor font16" href="https://wa.me/6281291255366" target={"_blank"}>
                          <img src="images/IconFooterIWhatsapp.png?v=1" style={{width: "15px", marginRight: "5px"}} alt="" />
                          0812 9125 5366
                        </a>
                      </p>
                      <p>
                        <a className="whiteColor font16" href="https://www.instagram.com/sayuranpagi_/" target={"_blank"}>
                        <img src="images/IconFooterInstagram.png?v=1" style={{width: "15px", marginRight: "5px"}} alt="" />
                          @sayuranpagi_
                        </a>
                      </p>
                </Content>
              </div>
 
              
            </StyleP>

            
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Content = styled.div`
width: 135px;

@media (max-width: 450px){
  width: 135px;
}
@media (max-width: 380px){
  width: 120px;
  a{
    font-size: 0.87rem;
  }
    
  
}
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  top: -2px;
`;
const InnerWrapper = styled.div`
  
`;
const StyleP = styled.p`
  margin-left: 5px;
  @media (max-width: 550px) {
    margin: 20px 20px;
  }
`;
const Logo = styled.img
`width: 60px;
@media(max-width: 500px){
  width: 40px;
}
`;


