import React from "react";
import styled from "styled-components";
import {FaMapMarkerAlt} from "react-icons/fa"

export default function SecondaryButton({ title, action, border }) {
  return (
    <Wrapper
      className="animate pointer radius7"
      onClick={action ? () => action() : null}
      border={border}
    >
      <p className="font18 Palanquin Bold" style={{paddingRight: "0px", maxWidth: "100%" , paddingTop: "0px"}}>{title}</p>
      
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#707070" : "#314814")};
  background-color: ${(props) => (props.border ? "#ffffff" : "#314814")};
  width: 180px;
  height: 42px;
  padding: 0px 10px; 
  outline: none;
  color: ${(props) => (props.border ? "#314814" : "#fff")};
  :hover {
    background-color: #314814;
    
    border: 1px solid #314814;
    color: ${(props) => (props.border ? "#fff" : "#314814")};
  }
`;

