import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import CheckMark from "../../assets/svg/Checkmark";

export default function PricingTable2({ img, price, title, text,  offers, action }) {
  let getIcon;

  

  return (
    <Wrapper className=" radius8 whiteBg flexCenter">
      <div className="flexColumn">
      <ImgBtn className="aniamte pointer" onClick={action ? () => action() : null}>
        <img className="radius8" src={img} alt="project" style={{height: "42px"}}></img>
      </ImgBtn>
        
      </div>
      <div style={{ margin: "0px 0" }} className="flexColumn">
        <h4 className="font24 bold greenColor">{title}</h4>
        <p className="font15" style={{width: "250px"}}>{text}</p>
      </div>
      
      
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: auto;
  padding: 10px 0px;
  text-align: left;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.1)  ; 
  @media (max-width: 1061px){
    padding: 10px 0px;
    margin-top: 35px;
  }
`;

const ImgBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding-right: 10px;
  
  :hover > img {
    opacity: 0.5;
  }
`;
