import React from "react";
import styled from "styled-components";
// Components

import {RiDoubleQuotesL} from "react-icons/ri"
import {RiDoubleQuotesR} from "react-icons/ri"

export default function VisiSP() {
  return (
    <Wrapper id="projects " >
      <div className="flexCenter" style={{}}>
        <VisiContent className="flex BgWhite" style={{ }}>
          <RiDoubleQuotesL className="quotesIcon" />
          <Content className=" "style={{textAlign: "center"}}>
            
            <span className="font24 plainColor PT-Sans italicFont">
              Dengan mengusung visi pertanian yang presisi, menggunakan 
              metode hidroponik dan teknologi, Sayuran Pagi berharap bisa 
              menjadi langkah kecil untuk mengatasi <b className="font24 plainColor PT-Sans italicFont Bold">permasalahan pertanian</b>.  
              <br/>Sayuran Pagi juga bertekad<b className="font24 plainColor PT-Sans italicFont Bold"> menyediakan kemudahan bagi petani </b>
              dalam menerapkan <b className="font24 plainColor PT-Sans italicFont Bold">pertanian berkelanjutan</b><br/> dan menyediakan 
              akses gaya hidup sehat dengan menghasilkan sayuran yang 
              berkualitas dan bernutrisi.</span>
          </Content>
          <RiDoubleQuotesL className="quotesIcon flexRight" style={{alignSelf: "end", transform: 'rotateY(180deg)'}} />
          
        </VisiContent>
      </div>
      
    </Wrapper>
  );
}

const Content = styled.div`

flex-direction: column;
display: flex;
justify-content: center;
p{
  max-width: 100%;
}
}
@media (max-width: 1440px) {
  
  
}

@media (max-width: 992px) {
  
  
}
`;

const Wrapper = styled.section`
  
  padding-bottom: 0px;
  width: 100%;
  
`;

const VisiContent = styled.div`
max-width: 1200px;
flex-direction: column; 
padding: 0px 3%;

@media (max-width: 1430px){
  max-width: 98%;
}
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
