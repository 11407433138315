import React,{ useState } from "react";
import styled from "styled-components";
//import { HashLink } from "react-scroll";
import { HashLink, NavHashLink } from 'react-router-hash-link';
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import {RiArrowDropDownLine} from "react-icons/ri"
import {RiArrowDropUpLine} from "react-icons/ri"
//import LogoIcon from "../../assets/svg/Logo";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const [subnav, setSubnav] = useState(false);
  return (
    <Wrapper className="animate whiteBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexRight">
        <div className="greenBgClose radius6"><CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
          <CloseIcon />
        </CloseBtn></div>
        
      </SidebarHeader>
      <div className="flexCenter">
        <Logo src="images/img_image1.png?v=1"/>
                
      </div>
      <UlStyle className=" flexColumn">
        <li className="semiBoldWhite font25 pointer">
          <NavHashLink
            onClick={() => {
              setSubnav(!subnav);
              toggleSidebar(!sidebarOpen);
            }}
            activeClassName={{ color: "#ffffff"}}
            className=""
            style={{ padding: "4px 100% 4px 25px"  }}
            to="/#home"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Home
          </NavHashLink>
        </li>

        
          <li className="semiBold font25 pointer" style={{display: "none"}}>
            <NavHashLink
              onClick={() => setSubnav(!subnav)}
              activeClassName="greenBg whiteColor"
              className=" flexSpaceCenter"
              style={{ width: "100%", padding: "7px 10% 0px 25px"   }}
              to="/belanja"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Belanja Produk
              {(subnav? 
              <RiArrowDropUpLine size={30}/>
              :
              <RiArrowDropDownLine size={30}/>
              )}
            </NavHashLink>
          </li>
      
        {(subnav ?
          <div class="dropdown-container2" id="Market City">
            <li className="semiBold font25 pointer" style={{display: "none"}}>
              <NavHashLink
                onClick={() => {
                  
                  toggleSidebar(!sidebarOpen);
                }}
                activeClassName="greenBg whiteColor"
                className=""
                style={{ padding: "0px 70px 0px 35px" }}
                to="/belanjaUntukRumah"
                spy={true}
                smooth={true}
                offset={-60}
              >
                Belanja Untuk Rumah
              </NavHashLink>
            </li>
            <li className="semiBold font25 pointer" style={{display: "none"}}>
              <NavHashLink
                onClick={() => {
                  
                  toggleSidebar(!sidebarOpen);
                }}
                activeClassName="greenBg whiteColor"
                className=""
                style={{ padding: "7px 70px 0px 35px" }}
                to="/belanjaUntukBisnis"
                spy={true}
                smooth={true}
                offset={-60}
              >
                Belanja Untuk Bisnis
              </NavHashLink>
            </li>
                        
            </div>
        : '' )}
        <li className="semiBold font25 pointer textLeft" style={{display: "none"}}>
          <HashLink
            onClick={() => {
              setSubnav(!subnav);
              toggleSidebar(!sidebarOpen);
            }}
            activeClassName="greenBg whiteColor"
            className=" flexSpaceCenter"
            style={{ padding: "4px 0px 4px 25px" }}
            to="/#belajarHidroponik"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Belajar Hidroponik
          </HashLink>
        </li>
        <li className="semiBold font25 pointer">
          <NavHashLink
            onClick={() => {
              setSubnav(!subnav);
              toggleSidebar(!sidebarOpen);
            }}
            activeClass="greenBg whiteColor"
            className=""
            style={{ padding: "4px 50% 4px 25px"  }}
            to="/TentangKami"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Tentang Kami
          </NavHashLink>
        </li>
        <li className="semiBold font25 pointer" style={{display: "none"}}>
          <NavHashLink
            onClick={() => {
              setSubnav(!subnav);
              toggleSidebar(!sidebarOpen);
            }}
            activeClass="greenBg whiteColor"
            className=""
            style={{ padding: "7px 100% 0px 25px"  }}
            to="pricing"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Blog
          </NavHashLink>
        </li>
        
      </UlStyle>
      
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 320px;
  height: 100vh;
  position: fixed;
  top: 0;
  
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    
  }
`;
const SidebarHeader = styled.div`
  padding: 20px;
  object-position: right;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  width:100%;
  padding: 20px 0px 20px 0px;
  li {
    margin: 5px 0;
  }
`;

const Logo = styled.img`
width: 100px ; 
object-position: center `;

const DropdownText= styled.div`
width: 60%;
@media (max-width: 947px){
  width: 100%;
  }
`;

const ButtonMap = styled.a`
padding: 0 20px;
width: 220px;
margin: 0 10px;
display: flex;
@media (max-width: 947px){
padding: 0 0px;
width: 0px;
margin: 0 0px;
display: none;
}
`;