import React,{ useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import styled from "styled-components";
// Assets

import ContactImg1 from "../../assets/img/foto2.png";
import ContactImg2 from "../../assets/img/foto3.png";
import ContactImg3 from "../../assets/img/foto1.png";



export default function Contact() {
  const form = useRef();

  const [result, showResult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_pkz2le2", "template_60n7ufg", e.target, "Xgek9Edta9O06aW2r")
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      showResult(true);
  };

  return (
    <Wrapper id="contact">
      <div className="whiteBg">
        <div className=""style={{}}>
          <div className=" row" >
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
            <ContactImage className="bgContact" style={{}} >
              <Content className="" style={{}}>
                <Img>
                  <img src="images/img_image1.png" style={{width: "40%", height: "auto", display: "flex"}}/>
                </Img>

                <p className="font25 whiteColor semiBold" style={{width: "250px",}}>
                Jalan Terong No.1, Beji, 
                Kec.Beji, Depok,  Jawa Barat
                16421
                  </p>
                <TextContent className="row " style={{}}>
                  <img src="images/Icon_whatsapp.png"/>
                  <h2 className="whiteColor regular" style={{paddingLeft: "10px"}}>
                  0812 9125 5366
                  </h2>
                </TextContent>
                <TextContent className="row " style={{padding: "0px 10px"}}>
                  <img src="images/Icon_instagram.png"/>
                  <h2 className="whiteColor regular" style={{paddingLeft: "10px"}}>
                  @sayuranpagi_
                  </h2>
                </TextContent>
              </Content>
              
            </ContactImage>
              
            </div>
           
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4" style={{padding: "10px 30px", width: "40%"}}>
              <HeaderInfo>
                <h1 className="font40 extraBold">Hubungi Kami</h1>
                <p className="font20">
                Ingin bertanya tentang produk atau tertarik bermitra/belajar hidroponik di Sayuran Pagi?  Hubungi kami lewat Whatsapp, Instagram, atau melalui kanal di bawah ini.
                </p>
              </HeaderInfo>

              <Form  onSubmit={sendEmail} ref={form} >
                <label className="font13">Nama:</label>
                <input type="text" id="name" name="name" className="font20 extraBold" required />
                <label className="font13">Email:</label>
                <input type="email" id="email" name="email" className="font20 extraBold"  required />
                <label className="font13">Pesan/Pertanyaan:</label>
                <textarea rows="4" cols="50" type="text" id="message" name="message" className="font20 extraBold" required />

                <SumbitWrapper className="flex">
                <ButtonInput type="submit" value="Send" className="pointer animate radius8" onClick={() => alert("Pendaftaran mu sudah diterkirim ")} style={{ maxWidth: "220px" }} />
                </SumbitWrapper>
              </Form>
              
            
            </div>

          </div>
        </div>
        
      </div>
    </Wrapper>
  );
}


const TextContent = styled.div`
padding: 20px 10px;
@media(max-width: 560px){
  display:flex ;
  justify-content: center;

}
`;

const Content = styled.div `
width: 50% ;
flex-direction: column;
justify-content: right;
margin: 40px 0 ;
@media(max-width: 560px){
  text-align: center;
  
}
`;

const ContactImage = styled.div`
display: flex;
justify-content: right;
align-items: center;
@media (max-width: 991px) {
  justify-content: center;
}
`;


const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  width: 100%;
  padding: 0px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 10px 0 0px 0;
  Width: 100%;
  
  input,
  textarea {
    width: 100%;
    
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 2px solid #707070;
    height: 40px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 10px;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #314814;
  background-color: #314814;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;

const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

const Video = styled.video`
  position: relative;
  
  width: 140%;
  @media (max-width: 961px) {
    width: 100%;
    height: auto;
    right: 0px;
  }

  @media (min-width: 962px) {
    width: 105%;
    height: auto;
    
    
  }

  @media (min-width: 992px) {
    width: 103%;
    height: auto;
    
    
  }
  @media (min-width: 1230px) {
    width: 105%;
    height: auto;
    
    
  }

  @media (min-width: 1380px) {
    width: 120%;
    height: auto;
    
    
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.div`
  @media (max-width: 560px) {
    display:flex;
    justify-content: center;
    height: auto;
  }
`;







