import React from "react";
import styled from "styled-components";
// Components

// Assets


export default function TentangKami_Header() {
  return (
    <Wrapper id="projects " >
      <div className="bgHeader_tentangkami" style={{}}>
        <div className="flex gradientWhite" style={{height: "410px", alignItems: "end", justifyContent: "center", }}>
         
          <Content className=" "style={{textAlign: "center"}}>
            <h1 className="font40 plainColor Bold" style={{textAlign: "center"}}>Kami, Petani Sayuran Pagi</h1>
            <p className="font18 plainColor">Ingin berkontribusi untuk ketahanan pangan dan kesehatan melalui 
pertanian hidroponik yang didukung dengan teknologi</p>
          </Content>
          
          
        </div>
      </div>
      
    </Wrapper>
  );
}

const Content = styled.div`

flex-direction: column;
display: flex;
justify-content: center;
padding-bottom: 60px;
p{
  max-width: 650px;
}
}
@media (max-width: 1140px) {
  
  
}

@media (max-width: 992px) {
  padding: 10%;
  
}
`;

const Wrapper = styled.section`
  
  Padding-top: 60px;
  width: 100%;
  
`;

const Line = styled.div`
 width: 1px;
 height: 75px;
 
 

 @media (max-width: 991px) {
  width: 0px;
  height: 0px;
  
  padding 0px;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
