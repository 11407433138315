import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";

export default function MisiSayuranPagi() {
  return (
    <Wrapper id="home" className=" ">
      <ContentWrapper className="flexCenter">
        <LeftSide className="flex" style={{justifyContent: "end"}}>
          <Content>
            <h1 className="Bold font25 textRight plainColor"> Solusi Ketahanan Pangan</h1>
            <HeaderP className="font18 textRight plainColor PT-Sans">
              Kami menyadari bahwa sektor pertanian seringkali menghadapi 
              berbagai permasalahan seperti pada rantai pasok, harga komoditas, 
              kesuburan tanah untuk pertanian berkelanjutan, gagal panen, tenaga
              kerja, hingga kesejahteraan para petani yang menjadi ancaman bagi 
              ketahanan pangan (food security) di Indonesia. 
            </HeaderP>
            
          </Content>
        </LeftSide>
        <RightSide className="">
          <ImageWrapper className="bgImgMisi1">
            
          </ImageWrapper>
          
        </RightSide>
      </ContentWrapper>
      <ContentWrapper1 className="flexCenter" style={{}}>
        <LeftSide1 className="flex" style={{justifyContent: "end"}}>
          <ImageWrapper1 className="bgImgMisi2">
            
          </ImageWrapper1>
        </LeftSide1>
        <RightSide1 className="">
        <Content>
            <h1 className="Bold font25  plainColor"> Memenuhi Kebutuhan Nutrisi</h1>
            <HeaderP className="font18 plainColor PT-Sans">
            Sayuran Pagi hadir sebagai solusi atas permasalahan 
            yang dihadapi sektor pertanian dengan memadukan pertanian 
            hidroponik dan teknologi. Kami percaya, keberhasilan sektor 
            pertanian dapat membantu memenuhi kebutuhan, serta menjamin 
            nutrisi dan keamanan pangan untuk menciptakan kehidupan 
            keluarga Indonesia yang lebih sehat.
            </HeaderP>
            
          </Content>
          
        </RightSide1>
      </ContentWrapper1>
      <ContentWrapper className="flexCenter" style={{paddingTop: "4%"}}>
        <LeftSide className="flex" style={{justifyContent: "end"}}>
          <Content>
            <h1 className="Bold font25 textRight plainColor">  Presisi dan Efisien </h1>
            <HeaderP className="font18 textRight plainColor PT-Sans">
            Pertanian yang presisi dan efisien menjadi mimpi besar bagi 
            petani. Demikian juga dengan kami. Pemanfaatan teknologi 
            dalam metode hidroponik memiliki keunggulan dalam mendukung 
            pertanian yang lebih presisi dan terukur. Variabel pendukung 
            dalam penanaman sistem hidroponik  mampu dikontrol dan disesuaikan 
            dengan kebutuhan tanaman sehingga konsistensi dari sayuran yang 
            dihasilkan baik dari segi kualitas, nutrisi, hingga rasa juga lebih 
            terjaga. .
            </HeaderP>
            
          </Content>
        </LeftSide>
        <RightSide className="">
          <ImageWrapper className="bgImgMisi3">
            
          </ImageWrapper>
          
        </RightSide>
      </ContentWrapper>
    </Wrapper>
    
    
  );
}



const Content = styled.div`
width: 560px;
@media (max-width: 1120px){
  width: 100%;
}


`;

const ContentWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0px 0px;
  @media (max-width: 960px) {
    padding-top : 0px;
    
    flex-direction: column;
  }
`;

const ContentWrapper1 = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 4%;
  @media (max-width: 960px) {
    padding-top : 0px;
    
    flex-direction: column;
  }
`;

const Wrapper = styled.section`
  margin: 0 auto;
  padding: 0px 0px;
  max-width: 1440px;
  padding-top: 60px;
  padding-bottom: 40px;
  width: 100%;
  @media (max-width: 960px) {
    padding-top : 0px;
    
    
  }
  
  
  
`;
const LeftSide = styled.div`
  
  margin-right: 3%;
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin: 20px 0 0 0;
    text-align: center;
    padding: 0px 3% 0px 3%;
  }
  @media (max-width: 560px) {
    padding: 0px 3% 0px 3%;
  }
`;

const RightSide1 = styled.div`
  margin-left: 3%;
  margin-right: 0px;
  width: 50%;
  height: 100%;
  
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 20px 0 0 0;
    text-align: left;
    padding: 0px 3% 0px 3%;

  }
  @media (max-width: 560px) {
    padding: 0px 3% 0px 3%;
  }
`;

const RightSide = styled.div`

  margin-left: 3%;
  width: 50%;
  height: 100%;
  @media (max-width: 1290px) {
    align-items: start;
  }
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin-top: 30px;
    margin-left: 0px;
  }
`;

const LeftSide1 = styled.div`

  margin-right: 3%;
  width: 50%;
  height: 100%;
  @media (max-width: 1290px) {
    align-items: start;
  }
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
    padding-bottom: 20px;

    margin-right: 0px;
  }
`;

const HeaderP = styled.div`
  
  
  padding: 15px 0px 50px 0px;
  line-height: 1.9rem;
  @media (max-width: 1220px) {
    
    width: 100%;
  }
  @media (max-width: 960px) {
    
    
    padding: 2px 0px 0px 0px;
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  height: 300px;
  position: relative;
  border-bottom-left-radius: 5.5rem;
  
  padding: 0 0px;
  @media (max-width: 960px) {
    width: 100%;
    border-bottom-left-radius: 7.5rem;
    justify-content: center;
  }
  @media (max-width: 660px) {
    width: 100%;
    height: 250px;
    border-bottom-left-radius: 8.5rem;
    justify-content: center;
  }
  @media (max-width: 470px) {
    width: 100%;
    height: 200px;
    border-bottom-left-radius: 8.5rem;
    justify-content: center;
  }
`;

const ImageWrapper1 = styled.div`
  display: flex;
  height: 300px;
  position: relative;
  border-top-right-radius: 5.5rem;
  
  padding: 0 0px;
  @media (max-width: 960px) {
    width: 100%;
    border-top-right-radius: 7.5rem;
    justify-content: center;
  }
  @media (max-width: 660px) {
    width: 100%;
    height: 250px;
    border-top-right-radius: 8.5rem;
    justify-content: center;
  }
  @media (max-width: 470px) {
    width: 100%;
    height: 200px;
    border-top-right-radius: 8.5rem;
    justify-content: center;
  }
`;

const Img = styled.img`
  
  border-bottom-left-radius: 5.5rem;
  width: 649px;

  @media (max-width: 1260px) {
    height: 50%;
    width: 100%;
  }

  @media (max-width: 960px) {
    height: auto;
    width: 100%;
  }
`;
