import React from "react";
import styled from "styled-components";
import ProjectBox1 from "../Elements/ProjectBox1";
import Belajar_Hidroponik from "../Elements/Belajar_hidroponik";

// Components
import ProjectImg1 from "../../assets/img/projects/loka1.png?v=1";
import ProjectImg2 from "../../assets/img/projects/loka2.png?v=1";
import ProjectImg3 from "../../assets/img/projects/loka3.png?v=1";
import SecondaryButtonNoBorder from "../Buttons/buttonSecondary2";

export default function Efisiensi_Bertani() {
  
  
  return (

    <Wrapper id="services" className="" style={{  }}>
      
      <div className="" >
      <div className="darkGreenBg">
          <AdvertisingWrapper className="">
          <Advertising className="flexSpaceCenter">
              <AddLeft>
                
                <h2 className="font40 Bold whiteColor">Bagaimana Sayuran Ditumbuhkan</h2>
                <p className="font18 regular PT-Sans" style={{color: "#ffffff"}}>
                Dengan memanfaatkan teknologi,  sayuran kami tumbuh secara efisien 
                dengan hasil yang berkualitas dan stabil. <br/> Sayuran hidroponik tumbuh 
                di atas air mengalir yang diberi nutrisi untuk mempercepat proses 
                pertumbuhan dan meningkatkan kualitas tanaman.
                </p>
                
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexCenter">
                  <Video src="https://www.youtube.com/embed/nNq0FUb16FE" 
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; 
                    clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    ></Video>
                  
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </AdvertisingWrapper>
          <AdvertisingWrapper2 className="">
            <Advertising2 className="flexSpaceCenter">
              <AddLeft1>
                <h2 className="font40 Bold whiteColor">Efisiensi Proses Bertani dengan Lokatani Apps</h2>
                <p className="font18 PT-Sans" style={{color: "#ffffff"}}>
                Beberapa inovasi teknologi kami kembangkan secara mandiri untuk 
                mengatasi berbagai permasalahan di kebun dan meningkatkan mutu produk.
                </p>
                <WrapperButton>
                    <p className="font18 PT-Sans whiteColor" >
                      Informasi Lebih lanjut tentang inovasi teknologi kami
                    </p>
                    <div style={{width: "316px", height: "42px", marginTop: "20px"}}> 
                      <a href="https://www.lokatani.id/" target={"_blank"}>
                        <SecondaryButtonNoBorder title="Selengkapnya" />
                      </a>
                    </div>
                </WrapperButton>

                
              </AddLeft1>
              <AddRight1>
                <AddRightInner1 >
                <div className="flexSpaceNull">
                  <div className="row ">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      <ProjectBox1
                        img={ProjectImg1}
                        title="Otomasi dan Pengawasan Kebun"
                        text="Menggunakan internet of things untuk otomasi proses pengawasan dan pemeliharaan kebun."
                      
                      />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      <ProjectBox1
                        img={ProjectImg2}
                        title="Aplikasi Pengelolaan Inventaris"
                        text="Mengembangkan aplikasi inventaris untuk mendata kebutuhan dan hasil panen."
                        
                      />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      <ProjectBox1
                        img={ProjectImg3}
                        title="Kalkulator Hidroponik"
                        text="Mengembangkan kalkulator hidroponik untuk merencanakan jadwal penanaman dan scale-up kebun."
                        
                      />
                    </div>
                  </div>
                  </div>
                </AddRightInner1>
              </AddRight1>
              
            </Advertising2>
            <WrapperButton1 className='flexCenter'>
                  

                    <div style={{width: "90%", height: "42px", marginTop: "20px"}}> 
                      <a href="https://www.lokatani.id/" target={"_blank"}>
                        <SecondaryButtonNoBorder title="Selengkapnya"/>
                      </a>
                      
                    </div>
              </WrapperButton1>
          </AdvertisingWrapper2>
        </div>
      <Belajar_Hidroponik/>
      </div>
      
    </Wrapper>
    
  );
}

const WrapperButton1 = styled.div`
display: none;
@media (max-width: 860px){
  display:flex;
  padding-bottom: 20px;
}
`;

const WrapperButton = styled.div`
display: block;
@media (max-width: 860px){
  display:none;
}
`;

const AdvertisingWrapper = styled.div `width: 100%;
max-width: 1270px;
margin: 0px auto;
padding: 0px 30px;

justify-content: center;
@media (max-width: 859px) {

  padding: 0 15px;
}`;

const AdvertisingWrapper2 = styled.div `width: 100%;
max-width: 1270px;
margin: 0px auto;
padding: 0px 30px;
justify-content: center;
@media (max-width: 859px) {

  padding: 0 15px;
}`;

const Video = styled.iframe`
  filter: drop-shadow(0 2px 50px rgb(0 0 0 / 0.6));
  padding-top: 100px ;
  position: relative;
    
	  top: 0;
	  left: 0;
	  bottom: 0;
	  right: 0;
	  width:100%;
	  height: 500px;
  @media (max-width: 1240px) {
    width: 100%;
    height: 480px;
    
    
  }
  @media (max-width: 1160px) {
    width: 100%;
    height: 430px;
    
    
  }
  @media (max-width: 960px) {
    width: 100%;
    height: 400px;
    
    
  }
  @media (max-width: 860px) {
    width: 100%;
    height: 440px;
    
  }
  @media (max-width: 760px) {
    width: 100%;
    height: 410px;
    
  }
  @media (max-width: 670px) {
    width: 100%;
    height: 370px;
    
  }
  @media (max-width: 560px) {
    width: 120%;
    height: 325px;
    
  }
  @media (max-width: 470px) {
    width: 120%;
    height: 290px;
    
  }
  @media (max-width: 390px) {
    width: 120%;
    height: 270px;
    
  }
`;

const Wrapper = styled.section`
  width: 100%;
  
  justify-content: center;
  align-items: center;
  
  @media (max-width: 1060px) {
    padding: 0px 0;
  }
  
`;

const Advertising2 = styled.div`
  padding-top: 55px;
  padding-bottom: 100px;
  position: relative;
  
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 40px 0;
    margin: 0px 0 0px 0;
  }
`;

const Advertising = styled.div`
  
  
  position: relative;
  
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 0px 0;
    margin: 80px 0 0px 0;
  }
`;
const AddLeft = styled.div`
  padding-top: 40px;
  width: 40%;
  p {
    padding-top: 35px;
    max-width: 438px;
  }
  

  @media (max-width: 860px) {
    width: 100%;
    order: 1;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      padding-top:10px;
      margin: 0 auto;
      max-width: 70%;
    }
  }

  @media (max-width: 560px) {
    width: 100%;
    order: 1;
    text-align: center;
    h2 {
      text-align: left;
      line-height: 2.7rem;
      margin: 15px;
    }
    p {
      text-align: left;
      max-width: 90%;
      line-height: 1.6rem;
    }
  }
`;
const AddRight = styled.div`
  width: 60%;
  
  top: -70px;
  right: 0;
  padding-left: 2%;
 
  

  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 2;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;

const AddLeft1 = styled.div`
  width: 40%;
  
  h2{
    max-width: 400px;
  }
  p {
    padding-top:35px;
    max-width: 350px;
  }
  @media (max-width: 1240px) {
    width: 50%;
    p {
      max-width: 350px;
    }
  }

  @media (max-width: 860px) {
    justify-content: center;
    align-items: center;
    width: 100%;
    order: 1;
    text-align: center;
    h2 {
      
      max-width: 100%;
      line-height: 2.5rem;
      margin: 0px 15px 0 20px; 
    }
    p {
      margin: 0px 50px 0 50px;
      max-width:100%;
      padding-bottom:50px;
      padding-top: 20px;
    }
  }
  @media(max-width: 560px){
    h2{
      text-align: left;
    }
    p{
      padding-bottom:20px;
      margin: 0px 15px 0 20px;
      text-align: left;
    }
  }
`;
const AddRight1 = styled.div`
  width: 60%;
  
  
  right: 0;
  
  
  @media (max-width: 1240px) {
    top : 0;
    width: 60%;
    
  }

  @media (max-width: 860px) {
    width: 95%;
    position: relative;
    order: 2;

  }
`;
const AddRightInner1 = styled.div`
  width: 100%;
`;
