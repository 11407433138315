import React,{ useState } from "react";
import styled from "styled-components";
// Components
import PricingTable2 from "../Elements/PricingTable2";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import {FaMapMarkerAlt} from "react-icons/fa"

import CoffeeShop from "../../assets/img/Mitra/CoffeShop.png"
import Restaurant from "../../assets/img/Mitra/restaurant.png"
import Hotel from "../../assets/img/Mitra/hotel.png"

import ProjectBox1 from "../Elements/ProjectBox1";
import ProjectImg1 from "../../assets/img/projects/i1.png";

export default function ProdukList() {
  const [sidebar, setSidebar] = useState(false);

  const [subnav, setSubnav] = useState(false);
    
  const [ showSettings, setShowSettings ] = useState(true);
  
  const [ display, setDisplay ] = useState( 'none' )

    function Click2() {

        if ( display == 'none' ) {

            setDisplay( 'block' )

        } else if( display == 'block' ){

            setDisplay( 'none' )

        }

    }

    




  return (
    <Wrapper id="pricing">
    <div className="">
      <div className="" style={{padding: "20px"}}>
      
        <HeaderInfo>
        <h1 className="font40 Bold greenColor ">
        Belanja Untuk Rumah
        </h1>
        </HeaderInfo>
      
            
        <TablesWrapper className="flexSpaceNull">
          
          <TableBox className="flexCenter">
            <a className="" href="https://wa.me/6281291255366" target={"_blank"}>
              <PricingTable2
                img="images/img_whatsapp1.png"
                
                title="Pesan via Whatsapp"
                text="klik tombol ini atau 0812 9125 5366"
                
              />
            </a>
          </TableBox>

          <TableBox>
            <a className="" href="https://shopee.co.id/sayuranpagi.official" target={"_blank"}>
              <PricingTable2
                img="images/imas1.png"
                
                title="Beli di Toko"
                text="11 Mitra toko di JABODETABEK"
                
              />
            </a>
          </TableBox>
          
          <TableBox>
            <a className="" href="https://www.tokopedia.com/sayuranpagi" target={"_blank"}>
              <PricingTable2 
                  img="images/img_image11.png"
                  
                  title="Beli di Tokopedia"
                  text="Sayuran Pagi Official"
                  
                />
              </a>
          </TableBox>
          
          
          <TableBox>
            <a className="" href="https://shopee.co.id/sayuranpagi.official" target={"_blank"}>
              <PricingTable2
                img="images/img_image12.png"
                
                title="Beli di Shopee"
                text="Sayuran Pagi Official"
                
              />
            </a>
          </TableBox>

          
        </TablesWrapper>
        <h3 className="font40 greenColor" style={{ padding: "60px 3%"}}>
        Katalog Produk
        </h3>
        <ContentBox className="flexColumn whiteBg radius8 dropdown" style={{ }} >
                  <button onClick={() => setShowSettings(!showSettings)} className=" dropdown-btn"style={{height: "auto", width:"100%", padding:"20px 0"}}>
                    <div className="flexSpaceCenter row" style={{margin: "0 20px"}}>
                    
                      <img src="images/img_image13.png"alt="" style={{width: "10%"}}/>
                      
                    
                    <TextWrapper style={{ }} className="flexCenter">
                      
                        <div className="flexSpaceCenter" style={{borderBottom: "2px solid #707070" ,width:"100%"}}>
                          <h4 className="font16 semiBold" style={{width: "100%"}}>Halal Fresh</h4>
                          {(showSettings ?
                            <RiArrowDropUpLine size={50}/>
                          : <RiArrowDropDownLine size={50}/> )}
                          </div>
                    </TextWrapper>
                    </div>
                  </button>
                  {(showSettings ?
                    <div class="dropdown-container2">
                      <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                    <h4 style={{}}>Halal fresh Cinere</h4>
                                    <p>Jl. Bukit Cinere Raya No.17A, Gandul,
                                    Kec. Cinere, Kota Depok, Jawa Barat 16514</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/halalCinere" target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>

                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                  <h4 style={{width: ""}}>Halal Fresh House</h4>
                                  <p>Jl. K.H.M. Yusuf Raya No.54b, Mekar Jaya,
                                  Kec. Sukmajaya, Kota Depok, Jawa Barat 16411</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/HalalFreshDepok" target={"_blank"}style={{}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                        <div className="flexRight  "style={{height: "50%",  padding:"0px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                  <h4 style={{width: ""}}>Halal Fresh House Cilendek</h4>
                                  <p>Jl. Brigjen Saptadji Hadiprawira No.2, RT.03/RW.13,Cilendek Bar.
                                    , Kec. Bogor Bar., Kota Bogor, Jawa Barat 16112</p>
 
                              </DropdownText>
                              <ButtonMap href="https://bit.ly/HalalFreshBogor" target={"_blank"} style={{}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                    </div>
                    : '' )}
                  <button id="hypermart" onClick={Click2} className=" dropdown-btn"style={{height: "auto", width:"100%", padding:"20px 0"}}>
                    <div className="flexCenter row" style={{margin:"0px 20px"}}>
                    
                      <img src="images/img_image21.png" alt="" style={{width: "14%"}}/>
                      
                    
                    <TextWrapper style={{  }} className="flexCenter">
                      
                        <div className="flexSpaceCenter" style={{borderBottom: "2px solid #707070" ,width:"100%"}}>
                          <h4 className="font16 semiBold" style={{width: "100%"}}>Hypermart</h4>
                          <RiArrowDropDownLine size={50}/>
                          </div>
                    </TextWrapper>
                    </div>
                  </button>
                  
                    <div class="dropdown-container" id="Hypermart" style={{display:display}}>
                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                        <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                                  <h4 style={{}}>Hypermart Thamrin City</h4>
                                  <p>Jl. Kebon Kacang Raya RT.4/RW.9, Kecamatan Tanah Abang,
                                     Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10230</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/HypermartThamrinCity"  target={"_blank"}style={{}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>

                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{}}>Hypermart Bella Terra</h4>
                                  <p>Jl. Boulevard Raya No.1, RT.4/RW.17, 
                                    Klp. Gading Tim., Kec. Klp. Gading, 
                                    Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14240</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/HypermartBellaTerra"  target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{}}>Hypermart Gajah Mada</h4>
                                  <p>Jl. Gajah Mada No.19, RT.2/RW.1, North Petojo, Gambir,
                                     Central Jakarta City, Jakarta 10130</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/HypermartGajahMada"target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{}}>Hypermart Supermall Karawaci</h4>
                                  <p>Supermal Karawaci, Jl. Boulevard Diponegoro, 
                                    Bencongan, Kec. Karawaci, Kabupaten Tangerang, Banten 15810</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/HypermartKarawaci"  target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{}}>Primo Cilandak Town Square</h4>
                                  <p>Jl. TB Simatupang No.kav 17, RT.6/RW.9, Kec. 
                                    Cilandak, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12430</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/HypermartCilandak"  target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{width: ""}}>Foodmart - Atrium Plaza</h4>
                              Atrium plaza, Jl. Senen Raya No.135, RW.2, Senen, Kec. 
                              Senen, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10410
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/FoodmartAtriumPlaza" target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{width: ""}}>Primo Maxxbox Karawaci </h4>
                              <p>Jl. Boulevard Jend. Sudirman No.1110, Bencongan, Kec. Klp. 
                                Dua, Kabupaten Tangerang, Banten 15810</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/PrimoKarawaci" target={"_blank"}style={{}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                    </div>
                  
                    <button id="FoodStory" className=" dropdown-btn"style={{height: "auto", width:"100%", padding:"20px 0", display: "none"}}>
                    <div className="flexCenter row" style={{margin:"0px 20px"}}>
                    
                      <img src="images/img_fruitstory.png" alt="" style={{width: "14%"}}/>
                      
                    
                    <TextWrapper style={{  }} className="flexCenter">
                      
                        <div className="flexSpaceCenter" style={{borderBottom: "2px solid #707070" ,width:"100%"}}>
                          <h4 className="font16 semiBold" style={{width: "100%"}}>FoodStory - 2 Lokasi</h4>
                          <RiArrowDropDownLine size={50}/>
                          </div>
                    </TextWrapper>
                    </div>
                  </button>
                  
                    <div class="dropdown-container" id="FoodStory" style={{display:"none"}}>
                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                        <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{}}>Fruit Story Depok</h4>
                                  <p>Jl. Margonda Raya No.201, Kemiri Muka, Kec. 
                                    Pancoran Mas, Kota Depok, Jawa Barat 16423</p>
                              </DropdownText>
                              <ButtonMap href="https://bit.ly/FoodstoryDepok"  target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>

                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{}}>Fruit Story Xpress</h4>
                                  <p>Jl. Puri Indah Raya No.19, RT.1/RW.6, Kembangan Sel., <br/>
                                    Kec. Kembangan, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11610</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/FoodstoryExpress"  target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                        
                    </div>
                  
                    <button id="Allfresh"  onClick={() => setSubnav(!subnav)}className=" dropdown-btn"style={{height: "auto", width:"100%", padding:"20px 0"}}>
                    <div className="flexCenter row" style={{margin:"0px 20px"}}>
                    
                      <Img src="images/logoAllfresh.png" style={{}}/>
                      
                    
                    <TextWrapper style={{  }} className="flexCenter">
                      
                        <div className="flexSpaceCenter" style={{borderBottom: "2px solid #707070" ,width:"100%"}}>
                          <h4 className="font16 semiBold" style={{width: "100%"}}>All fresh</h4>
                          {(subnav ?
                            <RiArrowDropUpLine size={50}/>
                          : <RiArrowDropDownLine size={50}/> )}
                          </div>
                    </TextWrapper>
                    </div>
                  </button>
                  {(subnav ?
                    <div class="dropdown-container2" id="Allfresh" >
                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                        <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{}}>All Fresh Gatot Subroto</h4>
                                  <p>Jl. Gatot Subroto No.185, RT.12/RW.1, 
                                    Menteng Dalam, Kec. Tebet, Kota Jakarta 
                                    Selatan, Daerah Khusus Ibukota Jakarta 12870</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/AllfreshGatsu"  target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>

                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{}}>All Fresh Pulomas </h4>
                                  <p>All Fresh Avenue, Jl. Kayu Putih Raya No.1, Kayu Putih, 
                                    Kec. Pulo Gadung, Kota Jakarta Timur, 
                                    Daerah Khusus Ibukota Jakarta 13210</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/AllfreshPuloMas"  target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{}}>All fresh Panglima Polim</h4>
                                  <p>Jl. Panglima Polim IX No.24, RT.1/RW.6, Melawai, 
                                    Kec. Kby. Baru, Kota Jakarta Selatan, 
                                    Daerah Khusus Ibukota Jakarta 12160</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/AllfreshPolim"target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{}}>All Fresh Pantai Indah Kapuk</h4>
                                  <p>Jl. Pantai Indah Utara 2 Blok C1 No.185, RT.2/RW.7, Kapuk Muara, 
                                    Kec. Penjaringan, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14460</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/AllfreshPIK"  target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{}}>All Fresh TB Simatupang</h4>
                                  <p>Jl. TB Simatupang No.71, RT.10/RW.2, Cilandak Bar., 
                                    Kec. Cilandak, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12430</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/AllfreshTBsimatupang"  target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{width: ""}}>All Fresh Bogor</h4>
                              <p> Jl. Malabar 1 No.17, RT.01/RW.01, Babakan, Kecamatan 
                                Bogor Tengah, Kota Bogor, Jawa Barat 16128</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/ALlfreshBogor" target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{width: ""}}>All Fresh Puri Kembangan</h4>
                              <p> RT.4/RW.6 Kembangan Selatan, Jl. Pesanggrahan No.168 N, 
                                Meruya Utara, Kec. Kembangan, 11610</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/AllfreshPuri" target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                    </div>
                  : '' )}
                    <button id="MarketCity" onClick={() => setSidebar(!sidebar)} className=" dropdown-btn"style={{height: "auto", width:"100%", padding:"20px 0"}}>
                    <div className="flexCenter row" style={{margin:"0px 20px"}}>
                    
                      <Img src="images/logoMarketCity.png" style={{}}/>
                      
                    
                    <TextWrapper style={{  }} className="flexCenter">
                      
                        <div className="flexSpaceCenter" style={{borderBottom: "2px solid #707070" ,width:"100%"}}>
                          <h4 className="font16 semiBold" style={{width: "100%"}}>Market City</h4>
                          {(sidebar ?
                            <RiArrowDropUpLine size={50}/>
                          : <RiArrowDropDownLine size={50}/> )}
                          </div>
                    </TextWrapper>
                    </div>
                  </button>
                  {(sidebar ?
                    <div class="dropdown-container2" id="Market City">
                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                        <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{width: "60%"}}>
                              <h4 style={{}}>Market City Kota Wisata</h4>
                                  <p>Oregon Commercial Lot Blok sjc 2, Jl. Raya Kota Wisata 
                                    Indonesia No.03, Ciangsana, Kec. Gn. Putri, Kabupaten Bogor, Jawa Barat</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/MarketCityKotaWisata"  target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>

                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{}}>Market City PIK </h4>
                                  <p>Jl. Pantai Indah Kapuk No.12-23, RT.6/RW.2, Kamal Muara, 
                                    Kec. Penjaringan, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14470</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/MarketCityPIK"  target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{}}>Market City Muara Karang </h4>
                                  <p>15 Jalan Muara Karang Raya 48 15 18, RT.15/RW.18, Pluit, 
                                    Kec. Penjaringan, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14450</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/MarketCityMuaraKarang"target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                        <div className="flexRight  "style={{height: "60%",  padding:"10px 0"}} >
                            <div className="row flexSpaceCenter Divbg radius6"style={{ margin: "0px 0", width:"86%", padding: "0 20px" }}>

                              <DropdownText  className="flexColumn pointer " style={{}}>
                              <h4 style={{}}>Market City BSD</h4>
                                  <p>Jl. Bsd Raya Utama, BSD City Serpong Kavling De Park Lot 1 No.9-11, 
                                    RT.2/RW.06, Lengkong Kulon, Pagedangan, Tangerang Regency, Banten 15331</p>
                              </DropdownText>
                              <ButtonMap  href="https://bit.ly/MarketCityBSD"  target={"_blank"}style={{padding: "0 20px", width:"220px",margin: "0 10px"}}>
                                <div className="flexSpaceCenter radius8" style={{width: "100%" ,height: "40px", border: "2px solid", padding: "0 30px", margin: "0 0px" }}>
                                    <FaMapMarkerAlt size={25} />
                                    <h4>Lihat Peta</h4>
                                </div>
                              </ButtonMap>
                            </div>
                        </div>
                        
                    </div>
                  : '' )}

                </ContentBox>
        
      </div>
    </div>
  </Wrapper>
    
  );
}

const ImgMitra = styled.img`
width: 264px;
marginRight: 2%;
@media (max-width: 824px){
  width: 204px;
  
}

@media (max-width: 562px){
  width: 124px;
  
}

`;

const Img = styled.img`
padding: 0 20px ;
width: 14%;
@media (max-width: 1024px){
  padding: 0 2%;
  
}

`;

const DropdownText= styled.div`
width: 60%;
@media (max-width: 947px){
  width: 100%;
  }
`;

const ButtonMap = styled.a`
padding: 0 20px;
width: 220px;
margin: 0 10px;
display: flex;
@media (max-width: 947px){
padding: 0 0px;
width: 0px;
margin: 0 0px;
display: none;
}
`;

const Wrapper = styled.section`
  width: 100%;
  padding: 0px 0;
`;
const HeaderInfo = styled.div`
  padding: 30px 3%;

  margin-bottom: 0px;
  @media (max-width: 1060px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  width: 100%;
  flex-direction: row;
  @media (max-width: 1060px) {
    flex-direction: column;
  }
`;

const ContentBox =styled.div`
height: 550px;
width: 80%;
padding: 0px 0;
margin:0px 10%;
overflow-y:scroll;



`;
const TableBox = styled.div`
   
  width: 340px;
  margin : 0 2% ;
  @media (max-width: 1060px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
  @media (max-width: 1170px) {
    width: 331px;
    max-width: 370px;
    margin: 0 auto
  }
`;

const TextWrapper =styled.div`
margin: 0px 0;
width: 86%;

@media (max-width: 842px){
  
}



`;




