import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border }) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
    >
      <p className="font18 Bold Palanquin">{title}</p>
      
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#707070" : "#314814")};
  background-color: ${(props) => (props.border ? "transparent" : "#314814")};
  width: 100%;
  height: 52px;
  padding: 0px 0px; 
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  :hover {
    filter: drop-shadow(0 8px 6px rgb(49 72 20 / 0.45));
    border: 1px solid #314814;
    color: ${(props) => (props.border ? "#314814" : "#fff")};
  }
`;

