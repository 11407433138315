import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TopNavbar from "./components/Nav/TopNavbar";
import Landing from "./screens/Landing";
import TentangKami from "./screens/Tentangkami";
import BelanjaUntukRumah from "./screens/BelanjaUntukRumah";


const ProjectRoutes = () => {
  return (
    <Router>
      <TopNavbar />
      <Routes>
        <Route path="/" element={<Landing />} />
        
        
        <Route path="/TentangKami" element={<TentangKami />} />
      </Routes>
    </Router>
  );
};
export default ProjectRoutes;
