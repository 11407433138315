import React from "react";
import styled from "styled-components";
import {FaMapMarkerAlt} from "react-icons/fa"

export default function SecondaryButtonNoBorder({ title, action }) {
  return (
    <Wrapper
      className="animate pointer radius6 flexCenter"
      onClick={action ? () => action() : null}
      
    >
      <p className="font18 Palanquin Bold" style={{paddingRight: "0px", maxWidth: "100%" , paddingTop: "0px", paddingBottom: "0px"}}>{title}</p>
      
    </Wrapper>
  );
}

const Wrapper = styled.button`
  background-color: #ffffff;
  color: #314814;
  width: 100%;
  height: 100%;
  padding: 10px 10px; 
  outline: none;
  border: none;
  :hover {
    background-color: #ffffff;
    filter: drop-shadow(0 8px 6px rgb(0 0 0 / 0.65));
    border: 0px solid #314814;
    color: #314814;
  }
  @media (max-width: 860px){
    p{
      padding-bottom: 0px;
    }
  }
`;

